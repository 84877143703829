import React from 'react';
import { Fragment } from 'react';
import { Button ,Modal } from 'react-bootstrap';
import {  Card, ListGroup } from "react-bootstrap";
import { Container, Row, Col  } from 'react-bootstrap';

const ViewSubscription = (props) => {

  const handleClose = () => { 
    props.modal();
    console.log(props.subscription)
  }
  return ( 
    <Modal
    className="fade bd-example-modal-lg"
    show={props.show}
    size="lg"
 >
    <Modal.Header>
       <Modal.Title>Subscription Details</Modal.Title>
       <Button
          variant=""
          className="close"
          onClick={handleClose}
       >
          <span>&times;</span>
       </Button>
    </Modal.Header>
    <Modal.Body>   
         <Row>            
            <Col md={12} lg={6} className="mb-3">
              <label>ID:</label>
              <h6>{props.subscription._id.slice(0,6)}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Name</label>
              <h6>{props.subscription.name}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Amount:</label>
              <h6>{props.subscription.amount}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Status:</label>
              <h6>{props.subscription.status}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Number of Membership Levels</label>
              <h6>{Math.floor(Math.random() * 10)}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Number of Members</label>
              <h6>{Math.floor(Math.random() * 100)}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Max Data Storage Limit</label>
              <h6>1 GB</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Number of Folders</label>
              <h6>{Math.floor(Math.random() * 10)}</h6>
            </Col>
             <Col md={12} lg={6} className="mb-3">
              <label>Tier:</label>
              <h6>{props.subscription.tier}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Subscription Type:</label>
              <h6>{props.subscription.substype}</h6>
            </Col>            
            <Col md={12} lg={12} className="mb-3">
              <label>Description:</label>
              <h6>{props.subscription.description}</h6>
            </Col>
         </Row> 
       {/* <Card style={{overflow:"hidden"}}>
            <Card.Header>
              <Card.Title></Card.Title>
            </Card.Header>
            <Card.Body>
            <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer"
                id="example5"
                role="grid"
                aria-describedby="example5_info">
                 <tbody style={{display:"table-caption"}}>
                  <tr>ID: {props.subscription._id}</tr>
                    <tr>Name: {props.subscription.name}</tr>
                    <tr>Description: {props.subscription.description}</tr>
                    <tr>Tier: {props.subscription.tier}</tr>
                    <tr>Status: {props.subscription.status}</tr>
                    <tr>Amount: {props.subscription.amount}</tr>                   
                 </tbody>
                 </table>
            </Card.Body>
          </Card> */}
      </Modal.Body>
    <Modal.Footer>
       <Button
          variant="danger light"
          onClick={handleClose}
       >
          Close
       </Button>
       {/* <Button
          variant=""
          type="button"
          className="btn btn-primary"
          onClick={handleClose}
       >
          Save changes
       </Button> */}
    </Modal.Footer>
 </Modal>
   );
}
 
export default ViewSubscription;