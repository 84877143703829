import React from 'react';
import { Fragment } from 'react';
import { Button ,Modal } from 'react-bootstrap';
import {  Card, ListGroup } from "react-bootstrap";
import { Container, Row, Col  } from 'react-bootstrap';

const ViewOrganizer = (props) => {

  const handleClose = () => { 
    props.modal();
    console.log(props.organizer)
  }
  return ( 
    <Modal
    className="fade bd-example-modal-lg"
    show={props.show}
    size="lg"
 >
    <Modal.Header>
       <Modal.Title>Business Admin Details</Modal.Title>
       <Button
          variant=""
          className="close"
          onClick={handleClose}
       >
          <span>&times;</span>
       </Button>
    </Modal.Header>
    <Modal.Body>   
         <Row>
            <Col lg={12} md={12} className="mb-3 text-center">
               {/* <label>Logo:</label> */}
               <img src={props.organizer.image  ? props.organizer.image: 'https://macappbucket.s3.us-east-2.amazonaws.com/img.png'} alt="" style={{width:"15%"}}></img>
              <h6>{props.businessName}</h6>
              <h6> {props.organizer.company.length !== 0 ? props.organizer.company[0].name: "Demo Company"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>ID:</label>
              <h6>{props.organizer._id.toString(36).slice(20)}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Status:</label>
              <h6>{props.organizer.status}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Name</label>
              <h6>{props.organizer.firstName} {props.organizer.lastName}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Membership Name:</label>
              <h6>{props.organizer.membershipName ? props.organizer.membershipName:"Demo"}</h6>
            </Col>
         
            <Col md={12} lg={6} className="mb-3">
              <label>Email:</label>
              <h6>{props.organizer.email}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Phone Number</label>
              <h6>{props.organizer.phoneNumber}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Legal Company Name:</label>
              <h6>{props.organizer.businessName}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Role</label>
              <h6>{props.organizer.role ? props.organizer.role: "demo role"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Compnay Website:</label>
              <h6>{props.organizer.company.length !== 0 ? props.organizer.company[0].websiteURL:"democompany.com"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Company Address:</label>
              <h6>{props.organizer.company.length !== 0? props.organizer.company[0].address:"demo address"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>City:</label>
              <h6>{props.organizer.company.length !== 0? props.organizer.company[0].city:"demo city"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Country:</label>
              <h6>{props.organizer.company.length !== 0 ? props.organizer.company[0].country:"demo city"}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Brand Color:</label>
              <h6>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={props.organizer.brandColor ? props.organizer.brandColor: "#FFF090"} className="bi bi-circle-fill" viewBox="0 0 16 16">
  <circle cx="8" cy="8" r="8"/>
</svg>
                {/* {props.organizer.brandColor ? props.organizer.brandColor: "#FFF090"} */}
              </h6>
            </Col>
            {/* <Col md={12} lg={6} className="mb-3">
              <label>Status:</label>
              <h6>{props.organizer.status}</h6>
            </Col> */}
            {/* <Col md={12} lg={6} className="mb-3">
              <label>Logo:</label>
              <h6>{props.organizer.logo ? props.organizer.logo: "sample.jpg"}</h6>
            </Col> */}
         </Row>
      {/* <Card style={{overflow:"hidden"}}>
         <Card.Header>
            <Card.Title>{props.businessName}</Card.Title>
         </Card.Header>
         <Card.Body>
            <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                 <tbody style={{display:"table-caption"}}>
                  <tr>ID: {props.organizer._id}</tr>
                    <tr>First Name: {props.organizer.firstName}</tr>
                    <tr>Last Name: {props.organizer.lastName}</tr>
                    <tr>Email: {props.organizer.email}</tr>
                    <tr>Phone Number: {props.organizer.phoneNumber}</tr>
                    <tr>Business Name: {props.organizer.businessName}</tr>
                    <tr>Membership Name: {props.organizer.memberShipName ? props.organizer.memberShipName: "MemberApp"}</tr>
                    <tr>Logo: {props.organizer.logo ? props.organizer.logo: "sample.jpg"}</tr>
                    <tr>Brand Color: {props.organizer.brandColor ? props.organizer.brandColor: "#FFF090"}</tr>
                    <tr>Status: {props.organizer.status}</tr> 
                 </tbody>
                 </table>
            </Card.Body>
          </Card> */}
          </Modal.Body>
    <Modal.Footer>
       <Button
          variant="danger light"
          onClick={handleClose}
       >
          Close
       </Button>
       {/* <Button
          variant=""
          type="button"
          className="btn btn-primary"
          onClick={handleClose}
       >
          Save changes
       </Button> */}
    </Modal.Footer>
 </Modal>
   );
}
 
export default ViewOrganizer;