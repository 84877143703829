import { isValid } from 'date-fns';
import $ from 'jquery';

const formValidation = (eve) => {
    const currentClickedItem = eve.currentTarget;
    // console.log("current item",currentClickedItem)
    // console.log("current item val",currentClickedItem.textContent)
    // console.log("current item class",currentClickedItem.classList.contains('react-datepicker__day'))
    let inputList = [];
    const tagNameOfFormOrField = currentClickedItem.tagName;
    const inputTypeText = currentClickedItem.querySelectorAll('input[type="text"]')
    const inputTypeEmail = currentClickedItem.querySelectorAll('input[type="email"]')
    const inputTypeNum = currentClickedItem.querySelectorAll('input[type="number"]')
    const textareaField = currentClickedItem.querySelectorAll('TEXTAREA')
    const dropdown = currentClickedItem.querySelectorAll('select')
    console.log("tagNameOfFormOrField: ", tagNameOfFormOrField)
    console.log("textareaField:", textareaField)
    
    if (inputTypeText.length > 0) {
        inputList.push(...inputTypeText)
    }
    if (inputTypeEmail.length > 0) {
        inputList.push(...inputTypeEmail)
    }
    if (inputTypeNum.length > 0) {
        inputList.push(...inputTypeNum)
    }
    if (textareaField.length > 0) {
        inputList.push(...textareaField)
    }

    if (dropdown.length > 0) {
        inputList.push(...dropdown)
    }
    if (tagNameOfFormOrField == "FORM") {

        inputList.map((it) => {
            console.log("current input: ", it)
            let errorSpan = document.getElementById(`${it.name}`);
            if (it.required && it.value.trim() === "") {
                if (it.tagName != "SELECT") {
                    if (!errorSpan) {
                        $(it).after(`<span class="empty-input" id="${it.name}">${it.placeholder} required</span>`)
                    }
                }
                else {
                    $(it).after(`<span class="empty-input" id="${it.name}">${it.placeholder} required</span>`)
                }
            }
            else {
                $(errorSpan).remove()
            }
        })
    }
    else if (tagNameOfFormOrField == "INPUT") {
        let errorSpan = document.getElementById(`${currentClickedItem.name}`);
        if (currentClickedItem.value == "") {

            if (!errorSpan) {
                $(currentClickedItem).after(`<span class="empty-input" id="${currentClickedItem.name}">${currentClickedItem.placeholder} required</span>`)
            }
        }
        else {

            $(errorSpan).remove()
        }

    }
    else if (tagNameOfFormOrField == "SELECT") {
        let errorSpan = document.getElementById(`${currentClickedItem.name}`);
        if (currentClickedItem.value == "") {

            if (!errorSpan) {
                $(`[name=${it.name}]`).after(`<span class="empty-input" id="${it.name}">required</span>`)
            }
        }
        else {

            $(errorSpan).remove()
        }

    }
    else if (tagNameOfFormOrField == "TEXTAREA") {
        let errorSpan = document.getElementById(`${currentClickedItem.name}`);
        if (currentClickedItem.value == "") {

            if (!errorSpan) {
                $(currentClickedItem).after(`<span class="empty-input" id="${currentClickedItem.name}">required</span>`)
            }
        }
        else {

            $(errorSpan).remove()
        }

    }
    // else if(currentClickedItem.classList.contains('react-datepicker__day')==true){
    //     // if(tagNameOfFormOrField){
    //     //     console.log("sfgd")
    //     // }
    //     console.log("date pciker")
    // }
}
export default formValidation;