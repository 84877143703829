import axios from "axios";

// Setting up the common default config for axios
const accessToken = localStorage.getItem("token")
if (accessToken) axios.defaults.headers.common['Authorization'] = accessToken;
axios.defaults.headers.common['Content-Type'] = 'application/json';

let getBaseUrl = () => {
  return import.meta.env.VITE_BACKEND_API_URL;
}

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (401 === err.response.status) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      500 === err.response.status &&
      err.response.data?.includes("jwt expired")
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      return err;
    }
  }
);

let updateUser = async (id) => {
  var accessToken = localStorage.getItem("token")
  let config = {

    headers: {
      'Content-Type': 'application/json',
      "Authorization": accessToken
    }

  }
  let url = getBaseUrl();
  let body = {
    "firstName": "updated person"
  }
  await axios.put(`${url}/user/${id}`, body, config).then((res) => {
    console.log(res)
  }).catch((error) => console.log(error.message))
}

const updateAdmin = (id, data) => {
  return axios.put(`${getBaseUrl()}/subadmin/${id}`, data);
};

let deleteOrganizer = async (id) => {
  var accessToken = localStorage.getItem("token")
  let url = getBaseUrl();
  let config = {

    headers: {
      'Content-Type': 'application/json',
      "Authorization": accessToken
    }

  }
  await axios.delete(`${url}/subadmin/${id}`, config).then((res) => {
    console.log(res)
  })
}

let deleteSubscription = async (id) => {
  var accessToken = localStorage.getItem("token")
  let url = getBaseUrl();
  let config = {

    headers: {
      'Content-Type': 'application/json',
      "Authorization": accessToken
    }

  }
  await axios.delete(`${url}/sasubscription/${id}`, config).then((res) => {
    console.log("delete subs", res)
  })
}

async function sasubscription(userId) {
  let url = getBaseUrl();
  const configData = {
    headers: { "content-type": "application/json" },
  };
  const res = await axios.get(url + "/sasubscription", configData);
  console.log(res, "Subscription Data");
  return res.data;
}
async function updatePartnerSubsPlan(updatePartnerSubs) {
  let url = getBaseUrl();

  const res = await axios.post(url + "/subadmin/updatePartnerSubsPlan", updatePartnerSubs);
  console.log(res, "Subscription Data");
  return res.data;
}

export { updateUser, updateAdmin, deleteOrganizer, deleteSubscription, getBaseUrl, sasubscription, updatePartnerSubsPlan }