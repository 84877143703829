import React, { Fragment, useState } from 'react'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import './styles.css'
import profileAvatar from '../../../images/proavatar.png';
import showPasswordIcon from '../../../images/visibility.svg';
import linkImage from '../../../images/icn-general-addlink.svg';

const AppProfile = (props) => {
  const [userInfo, setUserInfo] = useState([])
  const [image, setImage] = useState('')

  //console.log("djdjdj", profileAvatar)

  React.useEffect(() => {
    const data = localStorage.getItem('userInfo')
    if (data) {
      const userInfo = JSON.parse(data)
      //console.log(userInfo);
      setUserInfo(userInfo.result)
    } else {
      return null
    }
  }, [])

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    //console.log("fileupload",fileUploaded)
    setImage(fileUploaded)
  }

  //console.log("image", image)

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
          <div className="profileHeader">
            <h5 className="profileHeaderText">
              Account Details - Personal Profile
            </h5>
          </div>

          <div className="editIconContainer">
            {!image ? (
              <>
                <img src={profileAvatar} className="avatarImage" />
                <div className="iconContainer">
                  <i
                    className="fa fa-pencil editIcon icons"
                    aria-hidden="true"
                    onClick={handleClick}
                  ></i>
                </div>
              </>
            ) : (
              <>
                <img src={image} className="avatarImage" />
                <div className="iconContainer">
                  <i
                    className="fa fa-pencil editIcon icons"
                    aria-hidden="true"
                    onClick={handleClick}
                  ></i>
                </div>
              </>
            )}
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              className="imagePicker"
            />
          </div>
        </Col>
        <Col lg={12}>
          <Form.Group
            as={Row}
            className="mb-3 mt-35"
            controlId="adminProfilePlanName"
          >
            <Form.Label column sm={3} className="textStyle">
              Plan Name
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Plan Name</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="adminProfileSubscription"
          >
            <Form.Label column sm={3} className="textStyle">
              Subscription
            </Form.Label>
            <Col sm={9}>
              <Button variant="type" className="active">
                Monthly
              </Button>
              <Button variant="type">Yearly</Button>
              <Button variant="type">Lifetime</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="adminProfileCharges">
            <Form.Label column sm={3} className="textStyle">
              Charges
            </Form.Label>
            <Col sm={9}>
              <Button variant="type">Monthly</Button>
              <Button variant="type" className="active">
                Yearly
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="adminProfileInvoice">
            <Form.Label column sm={3} className="textStyle">
              Invoice
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Paid/Due</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="adminProfileABN">
            <Form.Label column sm={3} className="textStyle">
              ABN
            </Form.Label>
            <Col sm={9}>
              <Form.Label>971234</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="adminProfileUniqueLink"
          >
            <Form.Label column sm={3} className="textStyle">
              Unique Link
            </Form.Label>
            <Col sm={9}>
              <Form.Label>
                <a href="">Https://uniquelink/link.html</a>
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="adminProfileData">
            <Form.Label column sm={3} className="textStyle">
              Data
            </Form.Label>
            <Col sm={9}>
              <Form.Label>4GB</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="adminProfileRegisteredMember"
          >
            <Form.Label column sm={3} className="textStyle">
              Registered Members
            </Form.Label>
            <Col sm={9}>
              <Form.Label>400 Members</Form.Label>
            </Col>
          </Form.Group>
        </Col>

        <Col lg={12} className="d-flex justify-content-center">
          <Button type="submit" variant="custom">
            Save Changes
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="profileHeader">
            <h5 className="profileHeaderText">Business Admin - Users</h5>
          </div>
        </Col>
        <Col lg={12}>
          <Table responsive hover size="sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Subscription</th>
                <th>Plan</th>
                <th>Subscription End Date</th>
                <th>Status</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
								<td>Otto</td>
                <td>@mdo</td>
								<td>Otto</td>
                <td>@mdo</td>
								<td>Otto</td>
                <td>Link <a href='#'><img src={linkImage} /></a></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
								<td>Otto</td>
                <td>@mdo</td>
								<td>Otto</td>
                <td>@mdo</td>
								<td>Otto</td>
                <td>Link <a href='#'><img src={linkImage} /></a></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AppProfile
