import React from 'react';
import axios from 'axios';
import swal from "sweetalert";
import APIConfig from '../../../config';

const CreateOrganizer = () => {
  const [data, setData] = React.useState({});

  const handleBlur = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);

  };
  const submitHandler = (e) => {
    e.preventDefault()
    axios.post(`${APIConfig.SUBADMIN_REGISTER_API_URL}`, data).then((res) => {
      console.log(res)
      if (res.data.message === "Success!") {
        swal(
          "Good job!",
          "Organizer Created",
          "success"
        )
      }
    }).catch((error) => swal("Oops", "User Already exist!", error.message))
  }
  const setActive = () => {
    data.status = "Active"
  }
  return (
    <div className="col-xl-6 col-lg-12" style={{ maxWidth: "100%" }}>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Create Organizer</h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <form onSubmit={submitHandler}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleBlur}
                    name="firstName"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleBlur}
                    name="lastName"

                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={handleBlur}
                    name="email"

                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Phone</label>
                  <input type="text" className="form-control" onChange={handleBlur} name="phoneNumber" />
                </div>
              </div>
              <div className="form-row">
                {/* <div className="form-group col-md-4">
              <label>Amount</label>
              <select
                defaultValue={"option"}
                id="inputState"
                className="form-control"
              >
                <option value="option" disabled>
                  Choose...
                </option>
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
              </select>
            </div> */}
                <div className="form-group col-md-6">
                  <label>Password</label>
                  <input type="password" className="form-control" onChange={handleBlur} name="password" />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" onChange={setActive} />
                  <label className="form-check-label">Status</label>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>);
}

export default CreateOrganizer;