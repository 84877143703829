import React, { ChangeEvent, Fragment } from 'react';
import axios from 'axios';
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import DropFile from "./DropFile";
import APIConfig from '../../../config';

type Email = {
   email: string;
   subject: string;
   body: string;
};

const defaultEmail: Email = {
   email: "",
   subject: "",
   body: "",
};

const Email = () => {
   const [email, setEmail] = React.useState("");
   const [subject, setSubject] = React.useState("");
   const [body, setBody] = React.useState("");

   const [send, setSend] = React.useState(0)
   const handleBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();

      if(e.target.name === "email"){
         setEmail(e.target.value);
      } else if(e.target.name === "subject"){
         setSubject(e.target.value);
      } else if(e.target.name === "body"){
         setBody(e.target.value);
      }

      console.log(email);
   }

   const clear = () => {
      setEmail("");
      setSubject("");
      setBody("");
   }

   const sendEmail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setSend(send + 1)
      e.preventDefault();
      var accessToken = localStorage.getItem("token")
      let config = {

         headers: {
            'Content-Type': 'application/json',
            "Authorization": accessToken
         }

      }
      await axios.post(`${APIConfig.SEND_MAIN_API_URL}`, email, config).then((res) => {
         console.log(res)
         if (res.data.status === 200) {
            swal(
               "Email sent succefully",
               "success"
            )
            // document.getElementById("email2").value = ""
            // document.getElementById("body2").value = ""
            // document.getElementById("subject2").value = ""

         }
      }).catch((error) => console.log(error))
   }




   return (
      <Fragment>
         <PageTitle activeMenu="Compose" motherMenu="Email" />
         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     <div className="email-right-box ml-0 ml-sm-4 ml-sm-0">
                        <div className="compose-content">
                           <form action="#" >
                              <div className="form-group col-md-4" style={{ paddingLeft: "0px" }}>
                                 {/* <label>Choose</label> */}
                                 {/* <select
                                    // defaultValue={props.subscription.tier}
                                    id="inputState"
                                    className="form-control"
                                    name="to"
                                    onChange={handleBlur}
                                    required
                                 >
                                    <option value="option" >
                                       Choose...
                                    </option>
                                    <option value="subadmin">Sub-admin</option>
                                    <option value="user">User</option>
                                 </select> */}
                              </div>
                              <div className="form-group">
                                 <input
                                    type="text"
                                    className="form-control bg-transparent"
                                    placeholder=" To:"
                                    name="email"
                                    onChange={e => handleBlur(e)}
                                    value={email}
                                    id="email2"
                                    required
                                 />
                              </div>
                              <div className="form-group">
                                 <input
                                    type="text"
                                    className="form-control bg-transparent"
                                    placeholder=" Subject:"
                                    onChange={handleBlur}
                                    value={subject}
                                    name="subject"
                                    id="subject2"
                                    required
                                 />
                              </div>
                              <div className="form-group">
                                 <textarea
                                    // id="email-compose-editor"
                                    className="textarea_editor form-control bg-transparent "
                                    rows={15}
                                    placeholder="Enter text ..."
                                    onChange={e => handleBlur(e)}
                                    value={body}
                                    name="body"
                                    id="body2"
                                    required
                                 ></textarea>
                              </div>
                           </form>
                           <h5 className="mb-4">
                              <i className="fa fa-paperclip"></i> Attatchment
                           </h5>
                           <DropFile email={email} send={send} />
                        </div>
                        <div className="text-left mt-4 mb-5">
                           <button
                              className="btn btn-primary btn-sl-sm mr-2"
                              type="button"
                              onClick={e => sendEmail(e)}
                           >
                              <span className="mr-2">
                                 <i className="fa fa-paper-plane"></i>
                              </span>
                              Send
                           </button>
                           <button
                              className="btn btn-danger light btn-sl-sm"
                              type="button"
                              onClick={() => clear()}
                           >
                              <span className="mr-2">
                                 <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                 ></i>
                              </span>
                              Clear
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   )
}


export default Email;