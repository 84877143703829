import axios from "axios";
import { BaseService } from './baseService';

class AdminService extends BaseService {

    getStats = async () => {
        let url = this.getBaseUrl();
        return axios.get(`${url}/admin/stats/all`)
            .then((res) => {
                //console.log(res.data);
                return res.data;
            })
            .catch((error) => console.log(error.message))
    }
}

export { AdminService };