import React, {useState} from 'react'

function useUserInfo() {
	const [userInfo, setUserInfo] = useState({});
	
	const data = localStorage.getItem('userInfo');
	React.useEffect(() => {
		if (data) {
			const userInfo = JSON.parse(data);
			setUserInfo(userInfo.result);
			console.log("🚀 ~ file: useUserInfo.js:14 ~ React.useEffect ~ userInfo.result", userInfo.result)
		} else {
      console.log("User Info not found!")
    }
	}, [data]);
	
  return { userInfo };
}
export default useUserInfo;