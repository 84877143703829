import React from "react";
import "../../../css/adminsearchfilter.css";

const SearchFilter = ({ setSearchFilter, searchFilter }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="border  search-filter-parent ">
          <input
            type="text"
            className="search-filter"
            placeholder="Search"
            name="srh"
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
          <i className="fa fa-search ml-3" aria-hidden="true"></i>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
