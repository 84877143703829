import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/App-icon-circle.png";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import { toasterConfiguration } from "../../helper/toastConfig";
import './styles/login.css';


const Login = ({ history }) => {
  let navigate = useNavigate();
  const [loginData, setLoginData] = useState({});
  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    await axios.post(import.meta.env.VITE_BACKEND_API_URL + "/admin/login", loginData).then((res) => {
      if (res.data) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token)
        toast('Succesful');
        setTimeout(() => {
          navigate("/home")
          window.location.reload()
        }, 1000)

      } else {
        toast('Login Failed');
      }
    }).catch((error) => {
      toast('Login Failed');
      console.log(error.message)
    });
  };

  React.useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate("/home")
    } else {
      navigate('/')
    }
  }, [])



  return (
    <div className="Container">
      <div className="authincation h-100 p-meddle" style={{
        backgroundColor: '#8fd0d5'
      }}>
        <div className="container h-100" >
          <div className="row justify-content-center h-100 w-100 align-items-center " >
            <div className="col-md-6">
              <div className="authincation-content" style={{
                backgroundColor: '#FFFFFF'
              }} >
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3 sm" >
                        <Link to="/">
                          <img style={{ width: "30%" }} src={Logo} alt="Logo" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4" style={{
                        color: '#172B4D',
                        fontFamily: 'normal normal medium 26px/41px Poppins',
                      }}>
                        Sign in your account
                      </h4>
                      <form action="" onSubmit={(e) => submitHandler(e)}>
                        <div className="form-group">
                          <label className="mb-1 text-white">
                            <strong style={{ color: '#7A869A', fontWeight: 'normal' }}>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control change_color_text"
                            placeholder="Enter Email Address"
                            onChange={handleBlur}
                            name="email"
                            required
                            color="black"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 text-white">
                            <strong style={{ color: '#7A869A', fontWeight: 'normal' }}>Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={handleBlur}
                            name="password"
                            required
                          />
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 text-white">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="basic_checkbox_1"
                                onChange={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="basic_checkbox_1"
                                style={{
                                  color: '#5A5A5A',
                                  fontFamily: 'normal normal medium 14px/20px Poppins',
                                }}
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                          <div className="form-group" style={{ display: "none" }}>
                            <Link
                              className="text-white"
                              to="page-forgot-password"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn text-white btn-block bg-image hover-overlay button_hover"
                            style={{
                              backgroundColor: '#00AEB3',
                              textAlign: 'center',
                            }}

                          >
                            Sign-In
                          </button>
                          <ToastContainer />
                        </div>
                      </form>
                      <div className="new-account mt-3" style={{ display: "none" }}>
                        <p className="text-white">
                          Don't have an account?{" "}
                          <Link className="text-white" to="page-register">
                            Sign up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
