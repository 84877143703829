import React, { Fragment, useState } from 'react'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import './styles.css'
import profileAvatar from '../../../images/proavatar.png'
import showPasswordIcon from '../../../images/visibility.svg'
import linkImage from '../../../images/icn-general-addlink.svg'

const AppProfile = (props) => {
  const [userInfo, setUserInfo] = useState([])
  const [image, setImage] = useState('')

  //console.log("djdjdj", profileAvatar)

  React.useEffect(() => {
    const data = localStorage.getItem('userInfo')
    if (data) {
      const userInfo = JSON.parse(data)
      //console.log(userInfo);
      setUserInfo(userInfo.result)
    } else {
      return null
    }
  }, [])

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    //console.log("fileupload",fileUploaded)
    setImage(fileUploaded)
  }

  //console.log("image", image)

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
          <div className="profileHeader">
            <h5 className="profileHeaderText">
              User Details - Personal Profile
            </h5>
          </div>

          <div className="editIconContainer">
            {!image ? (
              <>
                <img src={profileAvatar} className="avatarImage" />
              </>
            ) : (
              <>
                <img src={image} className="avatarImage" />
              </>
            )}
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              className="imagePicker"
            />
          </div>
        </Col>
        <Col lg={12}>
          <Form.Group
            as={Row}
            className="mb-3 mt-35"
            controlId="userProfileFirstName"
          >
            <Form.Label column sm={3} className="textStyle">
              First Name
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Benjamin</Form.Label>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="userProfileLastName"
          >
            <Form.Label column sm={3} className="textStyle">
              Last Name
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Yaw</Form.Label>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="userProfileSubscription"
          >
            <Form.Label column sm={3} className="textStyle">
              Subscription
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Growth Package</Form.Label>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="userProfileSubscriptionPlan">
            <Form.Label column sm={3} className="textStyle">
              Subscription Plan
            </Form.Label>
            <Col sm={9}>
              <Button variant="type">Monthly</Button>
              <Button variant="type" className="active">
                Yearly
              </Button>
              <Button variant="type">
                Lifetime
              </Button>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="userProfileEndDate">
            <Form.Label column sm={3} className="textStyle">
              End Date
            </Form.Label>
            <Col sm={9}>
              <Form.Label>5/10/1993</Form.Label>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="userProfileStatus">
            <Form.Label column sm={3} className="textStyle">
              Status
            </Form.Label>
            <Col sm={9}>
              <Form.Label>Active</Form.Label>
            </Col>
          </Form.Group>
        </Col>

        <Col lg={12} className="d-flex justify-content-center">
          <Button type="submit" variant="custom">
            Save Changes
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AppProfile
