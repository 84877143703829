import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteConfirm from "../../modals/deleteConfirmation";
import { Button } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { ToastContainer, toast } from "react-toastify";
import ViewSubscription from "../../modals/viewSubscription";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";

import APIConfig from "../../../config";
import "./Styles/HomeStyle.css";
import SearchFilter from "./SearchFilter";
const PABBLYURL = import.meta.env.VITE_PABBLY_API_URL;
const pabbly_username = import.meta.env.VITE_PABBLY_USER;
const pabbly_password = import.meta.env.VITE_PABBLY_PASSWORD;
const pabbly_product_id = import.meta.env.VITE_PABBLY_PRODUCT_ID;
const pabbly_redirect_url = import.meta.env.VITE_PABBLY_REDIRECT_URL;

function Subscriptions() {
  //Time Picker
  // const [selectedDate, handleDateChange] = useState(new Date());
  const [lifeTimeSwitch, setLifeTimeSwitch] = useState(false);
  const [lifeTimeAmount, setLifeTimeAmount] = useState('');
  const [show, setShow] = useState(true);
  const [showDelSubscriptionModel, setShowDelSubscriptionModel] =
    useState(false);
  const [subs, viewSubs] = useState({});
  const [modal, viewModal] = useState(false);
  const [showSubscriber, setSubscriber] = useState(false);
  const [edit, editModal] = useState({});
  const [viewEdit, viewEditModal] = useState(true);
  const [bulletPointCompare, setBulletPointCompare] = useState({});
  const [listOfBulletPointCompare, setListOfBulletPointCompare] = useState([]);
  const [listOfBulletPointCompareData, setListOfBulletPointCompareData] =
    useState({});
  const [trialCheckbox, setTrialCheckbox] = useState(false);
  const [trialDays, setTrialDays] = useState();
  const [pointerPlansDetails, setPointerPlansDetails] = useState({});
  const [listOfPointerPlansDetails, setListOfPointerPlansDetails] = useState(
    []
  );
  const [listOfBulletPointCompareDatails, setListOfBulletPointCompareDetails] =
    useState({});
  const [modules, setModules] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const [clickedRowPablyMonthlyId, setClickedRowPablyMonthlyId] = useState();
  const [clickedRowPablyYearlyId, setClickedRowPablyYearlyId] = useState();
  const [email, setEmail] = useState("");
  const [assignments, setAssignments] = React.useState([]);
  const [data, setData] = useState(
    document.querySelectorAll("#orders tbody tr")
  );
  const [newPointers, setNewPointers] = React.useState([]);

  const addPointer = () => {
    let arr = [...newPointers];
    const last = arr[arr.length - 1];
    const newId = last ? last.id + 1 : 1;
    arr.push({ id: newId, text: '' });
    setNewPointers(arr);
  };

  const removePoint = (id) => {
    let arr = [...newPointers];
    arr.splice(arr.findIndex((e) => e.id === id), 1);
    setNewPointers([...arr]);
  };

  const handlePointerUpdate = (e, index) => {
    let arr = [...newPointers];
    arr[index].text = e.target.value;
    setNewPointers([...arr]);
  };

  const addPointerComparePlans = () => {
    setListOfBulletPointCompare([
      ...listOfBulletPointCompare,
      bulletPointCompare,
    ]);
  };
  const addPointerPlanDetails = () => {
    setListOfPointerPlansDetails([
      ...listOfPointerPlansDetails,
      pointerPlansDetails,
    ]);
  };
  const [subscription, setSubscription] = useState([]);
  const sort = 7;
  const activePag = useRef(0);
  const [selectRowId, setId] = useState(null);
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEmail();
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleAddEmail = () => {
    if (email && validateEmail(email)) {
      setAssignments((prevAssignments) => [...prevAssignments, email]);
      setEmail("");
    }
  };
  const handleRemoveEmail = (indexToRemove) => {
    setAssignments((prevAssignments) =>
      prevAssignments.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleModules = (e) => {
    e.preventDefault();
    let cuurentAllowedModule = e.target.value;
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    var option = optionElement.getAttribute("data-id");
    let arr = [...modules];
    let uniqueChars = [];
    arr.forEach((item) => {
      console.log("cuurent item", item);
      if ((item.moduleId.name || item.name) !== cuurentAllowedModule) {
        uniqueChars.push(item);
      }
    });
    console.log("create before subscription duplication", uniqueChars);
    uniqueChars.push({ name: cuurentAllowedModule, moduleId: option });
    console.log("create after subscription duplication", uniqueChars);
    setModules(uniqueChars);
  };
  const removeModule = (name) => {
    let arr = [...modules];

    let index = arr.findIndex((e) => e?.moduleId?.name === name);
    if (index > -1) {
      arr.splice(index, 1);
    }
    setModules(arr);
  };

  const updateSubs = (row) => {
    console.log("clicked row", row)
    setLifeTimeAmount(row?.amountOneTime || 0);
    if (row?.amountOneTime && row?.amountOneTime > 0) {
      setLifeTimeSwitch(true);
    }
    setListOfBulletPointCompare(row.bulletPointComparison);
    let arr = [];
    for (let i = 0; i < row.textCompare.length; i++) {
      arr.push({ id: i, text: row.textCompare[i].text, originalId: row.textCompare[i]._id });
    }
    setNewPointers(arr);

    viewSubs(row);
    setTrialDays(row.trialDays);
    if (row.trialDays > 0) {
      setTrialCheckbox(true);
    }
    setModules(row.modules);
    setAssignments(Array.isArray(row.assignments) ? row.assignments : []);
    viewModal(true);
  };

  const handleEditPlan = (e) => {
    e.preventDefault();
    const data = { ...subs };
    if (e.target.name == "planName" || e.target.name == "description") {
      data[e.target.name] = e.target.value;
      viewSubs(data);
    } else {
      if (e.target.value >= 0) {
        data[e.target.name] = e.target.value;
        if (e.target.name === "amountOneTime") {
          setLifeTimeAmount(e.target.value)
        }
        viewSubs(data);
      } else {
        e.target.value = 0;
      }
    }
  };

  const handleBulletPoint = (e, id) => {
    e.preventDefault();
    const data = { ...subs };
    let index = data.bulletPointComparison.findIndex((elem) => elem._id === id);
    if (id) {
      data.bulletPointComparison[index].text = e.target.value;
    } else {
      setListOfBulletPointCompareData({
        ...listOfBulletPointCompareData,
        [e.target.name]: e.target.value,
      });
    }
    console.log("bullet points comparison", data.bulletPointComparison);
    viewSubs(data);
  };

  const handleTextCompare = (e, id) => {
    e.preventDefault();
    const data = { ...subs };
    let index = data.textCompare.findIndex((elem) => elem._id === id);
    if (id) {
      data.textCompare[index].text = e.target.value;
    } else {
      setListOfBulletPointCompareDetails({
        ...listOfBulletPointCompareDatails,
        [e.target.name]: e.target.value,
      });
    }
    viewSubs(data);
  };

  // const editSub = (row) => {
  //   editModal(row)
  //   viewEditModal(true)
  // }

  const closeEdit = (subs) => {
    viewModal(false);
    const data = { ...subs };

    const pointersToUpdate = [];

    for (let i = 0; i < newPointers.length; i++) {
      if (newPointers[i].text) {
        if (newPointers[i].id) {
          pointersToUpdate.push({ text: newPointers[i].text, _id: newPointers[i].originalId });
        } else {
          pointersToUpdate.push({ text: newPointers[i].text });
        }
      }
    }

    data.textCompare = pointersToUpdate;

    data.trialDays = trialCheckbox ? trialDays : 0;
    console.log(data, "all bullet points");
    callBackend(data).then((updatedSubs) => {
      fetchData();
      // if (updatedSubs?.data?.message == "Sucessfully Updated Subscription ") {
      // }
    });
  };

  const closeView = () => {
    viewModal(false);
  };

  // const deleteSubs = (e,id) => {
  //   e.preventDefault();
  //   setShow(true)
  //   setId(id)
  // }

  const pointerEdit = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const planEdit = () => {
    if (!viewEdit) {
      viewEditModal(true);
    } else {
      viewEditModal(false);
    }
  };

  const callBackend = async (data) => {
    data.modules = modules;
    data.assignments = assignments;
    console.log(data);
    var accessToken = localStorage.getItem("token");
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    const pab_config = {
      auth: {
        username: pabbly_username,
        password: pabbly_password,
      },
      headers: { "Content-Type": "application/json" },
    };
    let monthly_pabbly_data = {
      product_id: pabbly_product_id,
      plan_name:
        data["amountMonthly"] == "0" && data["amountYearly"] == "0"
          ? data["planName"] + " Free"
          : data["planName"] + " Monthly",
      plan_description: data["description"],
      plan_code: (data["planName"].toLowerCase() + "-monthly").replace(
        /\s/g,
        ""
      ),
      price: data["amountMonthly"], //Required only if plan type is flat_fee | per_unit | donation | variable
      currency_code: "AUD",
      billing_cycle: "lifetime", //Required specific|lifetime|onetime
      billing_period: "m", //Required m|w|y
      billing_period_num: "1", //Required
      plan_type: "flat_fee", //Required flat_fee | per_unit | volume | tiered | stairstep | donation | variable
      plan_active: "true",
      trial_period: data["trialDays"], //(data['amountMonthly'] == '0' && data['amountYearly'] == '0') ? data['trialDays'] : '0',
      trial_type: "day",
      assignments: data.assignments,
      redirect_url: pabbly_redirect_url,
      //"meta_data": JSON.stringify(data)
    };

    let yearly_pabbly_data = {
      product_id: pabbly_product_id,
      plan_name: data["planName"] + " Yearly",
      plan_description: data["description"],
      plan_code: (data["planName"].toLowerCase() + "-yearly").replace(
        /\s/g,
        ""
      ),
      price: data["amountYearly"], //Required only if plan type is flat_fee | per_unit | donation | variable
      currency_code: "AUD",
      billing_cycle: "lifetime", //Required specific|lifetime|onetime
      billing_period: "y", //Required m|w|y
      billing_period_num: "1", //Required
      plan_type: "flat_fee", //Required flat_fee | per_unit | volume | tiered | stairstep | donation | variable
      plan_active: "true",
      trial_period: data["trialDays"], //(data['amountMonthly'] == '0' && data['amountYearly'] == '0') ? data['trialDays'] : '0',
      trial_type: "day",
      assignments: data.assignments,
      redirect_url: pabbly_redirect_url,
      //"meta_data": JSON.stringify(data)
    };

    let lifetime_pabbly_data = {
      "product_id": pabbly_product_id,
      "plan_name": data['planName'] + ' Onetime',
      "plan_description": data['description'],
      "plan_code": (data['planName'].toLowerCase() + '-onetime').replace(/\s/g, ''),
      "price": data['amountOneTime'], //Required only if plan type is flat_fee | per_unit | donation | variable
      "currency_code": "AUD",
      "billing_cycle": "onetime",//Required specific|lifetime|onetime
      "plan_type": "flat_fee", //Required flat_fee | per_unit | volume | tiered | stairstep | donation | variable
      "plan_active": "true",
      "trial_period": (data['amountMonthly'] == '0' && data['amountYearly'] == '0') ? data['trialDays'] : '0',
      "trial_type": "day",
      "redirect_url": pabbly_redirect_url,
      "meta_data": JSON.stringify(data)
    };
    var updateAgain = false;
    if (data["amountYearly"] == "0" && data["amountMonthly"] == "0" && (data['amountOneTime'] == '0' || data['amountOneTime'] == '')) {
      axios
        .put(PABBLYURL + `plan/update/${data.pablyMonthlyPlanId}`, monthly_pabbly_data, pab_config)
        .then((free_res) => {
          if (free_res.data.status === "success") {
            data.pablyMonthlyPlanId = free_res.data.data.id;
            data.pablyYearlyPlanId = free_res.data.data.id;
            axios
              .put(`${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`, data, config)
              .then((res) => {
                console.log(res);
                if (res.data.message === "Subscription Updated Successfully") {
                  swal(
                    "Good job!",
                    "Subscription Added Successfully",
                    "success"
                  );
                  setTimeout(() => {
                    props.history.push("/subscription");
                  }, 1000);
                }
              })
              .catch((error) => {
                let plan_id = free_res.data.data.id;
                console.log(error.status);
                alert("Plan is not Updated. Please try again");
                swal("Oops", "Subscription already exist!", error.message);
              });
          }
        })
        .catch((error) => {
          console.log(error.status);
          alert("Plan is not Created. Please try again");
        });
    } else {
      return await axios
        .put(`${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`, data, config)
        .then((res1) => {
          axios
            .put(
              PABBLYURL + `plan/update/${data.pablyMonthlyPlanId}`,
              monthly_pabbly_data,
              pab_config
            )
            .then((res) => {
              console.log(
                res,
                "successfully updated pabbly monthy plan " +
                data.pablyMonthlyPlanId
              );
              if (res.data.status === "success") {
                axios
                  .put(
                    PABBLYURL + `plan/update/${data.pablyYearlyPlanId}`,
                    yearly_pabbly_data,
                    pab_config
                  )
                  .then((yr_res) => {
                    if (yr_res.data.status === "success") {
                      console.log(
                        yr_res,
                        "successfully updated pabbly yearly plan " +
                        data.pablyYearlyPlanId
                      );
                      data.pablyMonthlyPlanId = res.data.data.id;
                      data.pablyYearlyPlanId = yr_res.data.data.id;
                      if (lifeTimeSwitch && lifeTimeAmount != "" && lifeTimeAmount > 0) {
                        axios.put(PABBLYURL + `plan/update/${data.pablyLifeTimePlanId}`, lifetime_pabbly_data, pab_config)
                          .then((lf_res) => {
                            if (lf_res?.data?.status === 'success') {
                              data.pablyLifeTimePlanId = lf_res.data.data.id;
                              data.amountOneTime = lifeTimeAmount;
                              console.log(
                                lf_res,
                                "successfully added pabbly life time plan " +
                                lf_res.data.data.id
                              );
                            } else {

                              axios
                                .post(PABBLYURL + 'plan/create', lifetime_pabbly_data, pab_config)
                                .then((lf_res1) => {
                                  if (lf_res1.data.status === "success") {
                                    updateAgain = true;
                                    data.pablyLifeTimePlanId = lf_res1.data.data.id;
                                    data.amountOneTime = lifeTimeAmount;
                                    console.log(
                                      lf_res1,
                                      "successfully added pabbly life time plan " +
                                      lf_res1.data.data.id
                                    );
                                  }
                                })

                            }
                          }).catch((error) => {
                            console.log(error.status);
                            axios
                              .post(PABBLYURL + 'plan/create', lifetime_pabbly_data, pab_config)
                              .then((lf_res1) => {
                                if (lf_res1.data.status === "success") {
                                  updateAgain = true;
                                  data.pablyLifeTimePlanId = lf_res1.data.data.id;
                                  data.amountOneTime = lifeTimeAmount;
                                  console.log(
                                    lf_res1,
                                    "successfully added pabbly life time plan " +
                                    lf_res1.data.data.id
                                  );
                                }
                              })
                              .finally(() => {
                                console.log("executing first finally", updateAgain);
                                if (updateAgain) {
                                  axios
                                    .put(
                                      `${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`,
                                      data,
                                      config
                                    )
                                    .then((res2) => {
                                      console.log("update subscription response", res2);
                                    });
                                }
                              });
                          })
                      }
                    } else {
                      if (yr_res.data.message === "Plan doesn't exist") {
                        axios
                          .post(
                            PABBLYURL + "plan/create",
                            yearly_pabbly_data,
                            pab_config
                          )
                          .then((yr_res2) => {
                            if (yr_res2.data.status === "success") {
                              updateAgain = true;
                              data.pablyYearlyPlanId = yr_res2.data.data.id;
                              console.log(
                                yr_res2,
                                "successfully added pabbly yearly plan " +
                                yr_res2.data.data.id
                              );
                            }
                          });
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("error response1: ", error.response);
                    if (error.response?.status === 404) {
                      axios
                        .post(
                          PABBLYURL + "plan/create",
                          yearly_pabbly_data,
                          pab_config
                        )
                        .then((yr_res3) => {
                          if (yr_res3.data.status === "success") {
                            updateAgain = true;
                            data.pablyYearlyPlanId = yr_res3.data.data.id;
                            console.log(
                              yr_res3,
                              "successfully added pabbly yearly plan " +
                              yr_res3.data.data.id
                            );
                          }
                        })
                        .finally(() => {
                          console.log("executing first finally", updateAgain);
                          if (updateAgain) {
                            axios
                              .put(
                                `${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`,
                                data,
                                config
                              )
                              .then((res2) => {
                                console.log("update subscription response", res2);
                              });
                          }
                        });
                    }
                  })
                  .finally(() => {
                    console.log("executing second finally", updateAgain);
                    if (updateAgain) {
                      axios
                        .put(
                          `${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`,
                          data,
                          config
                        )
                        .then((res2) => {
                          console.log("update subscription response", res2);
                        });
                    }
                  });
              } else {
                if (res.data.message === "Plan doesn't exist") {
                  axios
                    .post(
                      PABBLYURL + "plan/create",
                      monthly_pabbly_data,
                      pab_config
                    )
                    .then((mn_res) => {
                      if (mn_res.data.status === "success") {
                        updateAgain = true;
                        data.pablyMonthlyPlanId = mn_res.data.data.id;
                        console.log(
                          mn_res,
                          "successfully added pabbly monthy plan " +
                          mn_res.data.data.id
                        );
                      }
                    });
                }
              }
            })
            .catch((error) => {
              console.log("error response2: ", error.response);
              if (error.response?.status === 404) {
                axios
                  .post(
                    PABBLYURL + "plan/create",
                    monthly_pabbly_data,
                    pab_config
                  )
                  .then((mn_res2) => {
                    if (mn_res2.data.status === "success") {
                      updateAgain = true;
                      data.pablyMonthlyPlanId = mn_res2.data.data.id;
                      console.log(
                        mn_res2,
                        "successfully added pabbly monthy plan " +
                        mn_res2.data.data.id
                      );
                    }
                  })
                  .finally(() => {
                    console.log("executing third finally", updateAgain);
                    if (updateAgain) {
                      axios
                        .put(
                          `${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`,
                          data,
                          config
                        )
                        .then((res2) => {
                          console.log(res2);
                        });
                    }
                  });
              }
            })
            .finally(() => {
              console.log("executing fourth finally", updateAgain);
              if (updateAgain) {
                axios
                  .put(
                    `${APIConfig.GET_ALL_SUBSCRIPTIONS}/${data._id}`,
                    data,
                    config
                  )
                  .then((res2) => {
                    console.log(res2);
                  });
              }
              console.log("update subscription response", res1);
              swal("Good job!", "Subscription updated Successfully", "success");
              return res1;
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const callBackendToPauseSubs = async (data) => {
    // data.modules = modules;
    // console.log(data)
    var accessToken = localStorage.getItem("token");
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    return await axios
      .patch(
        `${APIConfig.GET_ALL_SUBSCRIPTIONS}/pauseSubs/${data._id}`,
        data,
        config
      )
      .then((res) => {
        console.log(res);
        swal("Good job!", "Subscription updated Successfully", "success");
        return res;
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleActive = (row) => {
    if (row.status === "Active") {
      edit.status = "in-Active";
      callBackend(row, edit);
      row.status = "in-Active";
    } else {
      edit.status = "Active";
      callBackend(row, edit);
      row.status = "Active";
    }
  };

  let fetchData = async () => {
    var accessToken = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: accessToken,
      },
    };

    await axios
      .get(`${APIConfig.GET_ALL_SUBSCRIPTIONS}`, config)
      .then((res) => {
        if (res?.data?.result?.subscriptions) {
          setSubscription(res.data.result.subscriptions);
        }
      });
  };
  // pause Subscription on base of checkbox check

  const pauseSubscriptionSpecific = (selectedRowData, e) => {
    const data = {
      _id: selectedRowData._id,
      pauseSubscription: e.target.checked,
    };
    console.log("Pause Subscription", data);
    callBackendToPauseSubs(data).then((item) => {
      if (item.data.message == "Sucessfully Updated Subscription ") {
        fetchData();
      }
    });
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#orders tbody tr"));
    fetchData();
  }, []);

  const closeSubscription = () => {
    viewModal(false);
  };

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);
  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };

  const deleteSubscriptionAdminModel = (clikedRow) => {
    console.log(clikedRow, "delete subs");
    setShowDelSubscriptionModel(true);
    setId(clikedRow._id);
    setClickedRowPablyMonthlyId(clikedRow.pablyMonthlyPlanId);
    setClickedRowPablyYearlyId(clikedRow.pablyYearlyPlanId);
  };

  const closeSubscripber = () => {
    setSubscriber(false);
  };

  const showModal = () => {
    setShow(false);
  };
  const trialCheckboxCheckedInput = (e) => {
    setTrialCheckbox(e.target.checked);
  };

  return (
    <div>
      {show && (
        <DeleteConfirm
          modal={showModal}
          show={showDelSubscriptionModel}
          id={selectRowId}
          method="Subscription"
          updateDate={fetchData}
          clickedRowPablyMonthlyId={clickedRowPablyMonthlyId}
          clickedRowPablyYearlyId={clickedRowPablyYearlyId}
        />
      )}

      {showSubscriber && (
        <ViewSubscription
          modal={closeSubscripber}
          show={showSubscriber}
          subs={subs}
        />
      )}
      {!modal ? (
        <div>
          <div className="row  ">
            <div className="col-9">
              <SearchFilter
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
              />
            </div>

            <div
              className="d-sm-flex mb-lg-4 mb-2 col-3"
              style={{ float: "right" }}
            >
              <Link to="/create-subscription">
                <Button
                  style={{ backgroundColor: "#26AFB4", borderColor: "#26AFB4" }}
                  className="button_hover"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                    style={{ position: "relative", right: "3px" }}
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                  New Subscription
                </Button>{" "}
              </Link>
            </div>
          </div>
          <p>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <title>Superadmin</title>
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
              integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
              crossOrigin="anonymous"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
              integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
            />
          </p>
          <div className="subscription-plans">
            <div className="table-responsive text-center">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Plans</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Allowed Modules</th>
                    <th>Members</th>
                    <th>Folders</th>
                    <th>Data Limit</th>
                    <th>Membership Levels</th>
                  </tr>
                </thead>

                {subscription
                  .filter((item) => {
                    var subscriptionSearchFilter =
                      searchFilter.toLocaleLowerCase();
                    return subscriptionSearchFilter === ""
                      ? item
                      : item?.planName
                        ?.toLocaleLowerCase()
                        .includes(subscriptionSearchFilter) ||
                      item?.description
                        ?.toLocaleLowerCase()
                        .includes(subscriptionSearchFilter);
                  })
                  .map((row) => {
                    return (
                      <tbody key={row._id}>
                        <tr>
                          <td>
                            <p className="plan-heading">{row.planName}</p>
                          </td>
                          <td>
                            <span onClick={() => updateSubs(row)}>
                              <i
                                style={{ cursor: "pointer" }}
                                className="fas fa-edit"
                              />
                            </span>
                          </td>
                          <td>
                            <i
                              className="fa fa-trash"
                              onClick={() => {
                                deleteSubscriptionAdminModel(row);
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td colSpan={5} />
                        </tr>

                        <tr>
                          <td>
                            <p className="plans">{row.description}</p>
                          </td>
                          <td />
                          <td></td>
                          <td>
                            {row.modules.map((mod) => {
                              return (
                                <p className="module" key={mod._id}>
                                  {mod?.moduleId?.name || mod?.name}
                                </p>
                              );
                            })}
                          </td>
                          <td>
                            <p className="member">{row.membersAllowed}</p>
                          </td>
                          <td>
                            <p className="folder">{row.foldersAllowed}</p>
                          </td>
                          <td>
                            <p className="data-limit">{row.dataLimit} GB</p>
                          </td>
                          <td>
                            <p className="membership-level">
                              {row.membershipLevelAllowed}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              checked={row.pauseSubscription}
                              onChange={(e) =>
                                pauseSubscriptionSpecific(row, e)
                              }
                            />
                            <span className="ml-3">Pause New Subscription</span>
                          </td>
                          <td colSpan="5"></td>
                        </tr>
                        <tr>
                          <td>
                            <p className="plan-info">
                              ${row.amountMonthly} <span>Monthly</span>
                            </p>
                            <p className="plan-info">
                              ${row.amountYearly} <span>Annually</span>
                            </p>
                          </td>
                          <td colSpan="5"></td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
              <ToastContainer />
            </div>
          </div>
          <p />
        </div>
      ) : (
        <div>
          <p>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <title>Superadmin</title>
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
              integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
              crossOrigin="anonymous"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
              integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
            />
          </p>
          <div className="container-fluid view-plans">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#29B7C1"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
              style={{ cursor: "pointer" }}
              onClick={closeView}
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <div className="row">
              <div className="col-lg-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">View</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit{" "}
                      <strong style={{ paddingLeft: "10px" }}>
                        {" "}
                        {subs.planName}{" "}
                      </strong>
                    </li>
                  </ol>
                </nav>
                <form>
                  <div className="form-group">
                    <label className="label-title">Title</label>
                    <input
                      type="text"
                      className="form-control input-field"
                      value={subs.planName}
                      name="planName"
                      onChange={handleEditPlan}
                    />
                  </div>
                  <div className="form-group">
                    <label className="label-title">Description</label>
                    <textarea
                      type="text"
                      style={{ height: "80px" }}
                      className="form-control input-field"
                      placeholder="description"
                      value={subs.description}
                      name="description"
                      onChange={handleEditPlan}
                    />
                  </div>
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Number of Members</h5>
                      </div>
                      {subs.membersAllowed === "Unlimited" ? (
                        <div className="col-4 text-right">
                          <span
                            className="badge badge-info"
                            style={{ width: "70%" }}
                          >
                            {subs.membersAllowed}
                          </span>
                        </div>
                      ) : (
                        <div className="col-4 text-right">
                          <input
                            type="text"
                            className="form-control member-input"
                            value={subs.membersAllowed}
                            name="membersAllowed"
                            onChange={handleEditPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Membership Levels</h5>
                      </div>
                      {subs.membersAllowed === "Unlimited" ? (
                        <div className="col-4 text-right">
                          <span
                            className="badge badge-info"
                            style={{ width: "70%" }}
                          >
                            {subs.membershipLevelAllowed}
                          </span>
                        </div>
                      ) : (
                        <div className="col-4 text-right">
                          <input
                            type="text"
                            className="form-control member-input"
                            value={subs.membershipLevelAllowed}
                            name="membershipLevelAllowed"
                            onChange={handleEditPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Folders */}
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Number of Folders</h5>
                      </div>

                      {subs.foldersAllowed === "Unlimited" ? (
                        <div className="col-4 text-right">
                          <span
                            className="badge badge-info"
                            style={{ width: "70%" }}
                          >
                            {subs.foldersAllowed}
                          </span>
                        </div>
                      ) : (
                        <div className="col-4 text-right">
                          <input
                            type="text"
                            className="form-control member-input"
                            value={subs.foldersAllowed}
                            name="foldersAllowed"
                            onChange={handleEditPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Courses */}
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Number of Courses</h5>
                      </div>

                      {subs.coursesAllowed === "Unlimited" ? (
                        <div className="col-4 text-right">
                          <span
                            className="badge badge-info"
                            style={{ width: "70%" }}
                          >
                            {subs.coursesAllowed}
                          </span>
                        </div>
                      ) : (
                        <div className="col-4 text-right">
                          <input
                            type="text"
                            className="form-control member-input"
                            value={subs.coursesAllowed}
                            name="coursesAllowed"
                            onChange={handleEditPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Challenges */}
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Number of Challenges</h5>
                      </div>

                      {subs.challengesAllowed === "Unlimited" ? (
                        <div className="col-4 text-right">
                          <span
                            className="badge badge-info"
                            style={{ width: "70%" }}
                          >
                            {subs.challengesAllowed}
                          </span>
                        </div>
                      ) : (
                        <div className="col-4 text-right">
                          <input
                            type="text"
                            className="form-control member-input"
                            value={subs.challengesAllowed}
                            name="challengesAllowed"
                            onChange={handleEditPlan}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Data Limit */}
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Data Limit (GB)</h5>
                      </div>
                      <div className="col-4 text-right">
                        <input
                          type="text"
                          className="form-control member-input"
                          value={subs.dataLimit}
                          name="dataLimit"
                          onChange={handleEditPlan}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Number of Emails</h5>
                      </div>
                      <div className="col-4 text-right">
                        <input
                          type="text"
                          className="form-control member-input"
                          value={subs.emailsAllowed}
                          name="emailsAllowed"
                          onChange={handleEditPlan}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-8">
                        <h5 className="lifeTimeAmount">Lifetime</h5>
                      </div>
                      <div className="col-4 align-items-center d-flex justify-content-center pr-4">
                        <label class="lifeTimeSwitch">
                          <input type="checkbox" checked={lifeTimeSwitch} onChange={() => { setLifeTimeSwitch(!lifeTimeSwitch) }} />
                          <span class="sliderLifeTime roundLifeTime"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {lifeTimeSwitch &&
                    <div className="show-num">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="label-title">Lifetime Amount</h5>
                        </div>
                        <div className="col-4 text-right">
                          <input
                            type="number"
                            className="form-control member-input"
                            value={subs?.amountOneTime || 0}
                            name="amountOneTime"
                            style={{ height: "40px" }}
                            onChange={handleEditPlan}
                          />
                        </div>
                      </div>
                    </div>}
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Monthly Amount ($)</h5>
                      </div>
                      <div className="col-4 text-right">
                        <input
                          type="text"
                          className="form-control member-input"
                          value={subs.amountMonthly}
                          name="amountMonthly"
                          onChange={handleEditPlan}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="show-num">
                    <div className="row">
                      <div className="col-8">
                        <h5 className="label-title">Yearly Amount ($)</h5>
                      </div>
                      <div className="col-4 text-right">
                        <input
                          type="text"
                          className="form-control member-input"
                          value={subs.amountYearly}
                          name="amountYearly"
                          onChange={handleEditPlan}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={trialCheckbox}
                        onChange={trialCheckboxCheckedInput}
                      />
                      <label class="form-check-label">Trial (Optional)</label>
                    </div>
                    {trialCheckbox && (
                      <div>
                        <input
                          type="number"
                          className="form-control input-field"
                          value={trialDays}
                          name="trialDays"
                          onChange={(e) => setTrialDays(e.target.value)}
                          maxLength={2}
                        />
                      </div>
                    )}
                  </div>
                  <div className="allowed-module">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="label-title">Allowed Modules</h5>
                      </div>
                      <div className="col-12">
                        {modules.map((module) => {
                          return (
                            <Badge variant="info" key={module._id}>
                              {module?.moduleId?.name || module?.name}{" "}
                              <i
                                className="fas fa-times-circle"
                                onClick={() =>
                                  removeModule(module?.moduleId?.name)
                                }
                              ></i>
                            </Badge>
                          );
                        })}
                        <Form.Group className="mt-3">
                          <Form.Control as="select" onChange={handleModules}>
                            <option>Select allowed Modules</option>
                            <option
                              value="Content Library"
                              data-id="62b405bb6d51066641fe21c7"
                            >
                              Content Library
                            </option>
                            <option
                              value="Audio Library"
                              data-id="62b4060d6d51066641fe21d3"
                            >
                              Audio Library
                            </option>
                            <option
                              value="Video Library"
                              data-id="62b405e86d51066641fe21cf"
                            >
                              Video Library
                            </option>
                            <option
                              value="Photo Library"
                              data-id="62b4054d6d51066641fe21bb"
                            >
                              Photo Library
                            </option>
                            <option
                              value="Courses"
                              data-id="62b4062a6d51066641fe21d7"
                            >
                              Courses
                            </option>
                            <option
                              value="Challenges"
                              data-id="62b405946d51066641fe21c3"
                            >
                              Challenges
                            </option>
                            <option
                              value="Social Wall"
                              data-id="62b405ce6d51066641fe21cb"
                            >
                              Social Wall
                            </option>
                            <option
                              value="Event"
                              data-id="62b4056b6d51066641fe21bf"
                            >
                              Events
                            </option>
                            <option
                              value="Email"
                              data-id="6717ff36d964c3cfd1f163d5"
                            >
                              Email
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group className="mt-2">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="emails"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                        </Form.Group>
                        {assignments && assignments.length > 0 ? (
                          assignments.map((assignment, index) => (
                            <Badge key={index} variant="info" className="mr-2">
                              {assignment}{" "}
                              <i
                                onClick={() => handleRemoveEmail(index)}
                                className="fas fa-times-circle"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </Badge>
                          ))
                        ) : (
                          ""
                        )}
                      </div>


                      {/* <div style={{display:"grid"}}>
                {subs.modules.map((module) => {
                  return <input type="text"  style={{
                    width:"170px", padding: "5px", fontWeight:"700", borderRadius:"9px",
                    margin:"0px 38px", color:"#19B6BA", backgroundColor:"#8EDBDD4E",
                    borderColor:"#8EDBDD4E", display:"inline-block", textAlign:"center"
                  }} placeholder={module.name}/>
                } )}
                </div> */}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-custom btn-block"
                        onClick={() => closeEdit(subs)}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                {/* <div className="pointers">
                  <h3 className="title">Pointers for Compare Plans <i className="fas fa-exclamation-circle float-right" /></h3>
                  <span className="float-right"><i className="fas fa-edit" onClick={pointerEdit} style={{ cursor: "pointer" }} /></span>
                  <ul>
                    {listOfBulletPointCompare.map((row, ind) => {
                      return (
                        //   <div> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#26AFB3" className="bi bi-circle-fill" viewBox="0 0 16 16">
                        //   <circle cx="8" cy="8" r="8"/>
                        // </svg> 
                        show ? <li key={ind} style={{ margin: "0 0 12px 0" }}><textarea type="text" name={"editBulletPoint" + ind} className="form-control input-field" value={row.text} onChange={(e) => handleBulletPoint(e, row?._id)} style={{ height: "30px", position: "relative", overflowY: "hidden" }} /> </li> : <li
                        // style={{position:"relative",left:"20",bottom:"23px"}}
                        >{row?.text}</li>

                      )
                    })}

                  </ul>
                  <a href="#" className="add float-right" onClick={addPointerComparePlans}><i className="fas fa-plus" /> Add</a>
                  <div className="clearfix" />
                </div> */}
                <div className="pointers">
                  <h3 className="title">
                    Pointers for Plan Details{" "}
                    {/* <img src className="float-right" /> */}
                  </h3>
                  {/* <span className="float-right">
                    <i
                      className="fas fa-edit"
                      onClick={planEdit}
                      style={{ cursor: "pointer" }}
                    />
                  </span> */}
                  <ul>
                    {newPointers.map((pointer, ind) => {
                      return (
                        <div key={ind} className="form-group row">
                          <textarea
                            type="text"
                            name={"editBulletPointDetail" + ind}
                            className="form-control input-field col-lg-11"
                            value={pointer.text}
                            onChange={(e) => handlePointerUpdate(e, ind)}
                            style={{
                              height: "30px",
                              position: "relative",
                              overflowY: "hidden",
                            }}
                          />
                          <span className="col-lg-1 d-flex align-items-center">
                            <i className="fa fa-trash text-red" onClick={() => removePoint(pointer.id)} />
                          </span>
                        </div>
                      )
                    })}
                  </ul>
                  <a href="#" className="up float-right">
                    <i className="fas fa-chevron-up" />
                  </a>
                  <br />
                  <a
                    href="#"
                    className="add float-right"
                    onClick={addPointer}
                  >
                    <i className="fas fa-plus" /> Add
                  </a>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
          <p />
        </div>
      )}
    </div>
  );
}

export default Subscriptions;
