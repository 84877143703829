import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import ChartDonught2 from "../Sego/Home/donught2";
import ChartDonught3 from "../Sego/Home/donught3";
import BarChart from "../Sego/Home/BarChart";
// import RadialBarChart from "../Sego/Home/RadialBarChart";
import ActivityLineChart from "../Sego/Home/ActivityLineChart";
import ActivityLineChart2 from "../Sego/Home/ActivityLineChart2";
import ActivityLineChart3 from "../Sego/Home/ActivityLineChart3";
import TimeLineChart from "../Sego/Home/TimeLineChart";
import TimeLineChart2 from "../Sego/Home/TimeLineChart2";
import TimeLineChart3 from "../Sego/Home/TimeLineChart3";
import { Card, ListGroup, Table, Media, Modal, Button } from "react-bootstrap";
import { Link as Linked, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import './Styles/HomeStyle.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ToastContainer, toast } from 'react-toastify';
import {AdminService} from "../../../services/adminService";
import {StatCard} from "./StatCard";

import axios from "axios";
import APIConfig from '../../../config'

// // Map
// import World from "@svg-maps/world";
// import { SVGMap } from "react-svg-map";

// //** Import Image */
import menu9 from "../../../images/menus/9.png";
import menu10 from "../../../images/menus/10.png";
import menu11 from "../../../images/menus/11.png";
import menu12 from "../../../images/menus/12.png";
import profile from "../../../images/profile/download.png";
import { useState } from "preact/hooks";

const RadialBarChart = loadable(() =>
  pMinDelay(import("../Sego/Home/RadialBarChart"), 1000)
);

function Home() {
  useEffect(() => {
    document.querySelector("#BarChart canvas").classList.add("lineChart");
  });


  const [admin, setAdmin] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [subscription, setSubscription] = React.useState([]);
  const [payment, setPayment] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [history, setHistory] = React.useState([]);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [activeUsers, setActiveUsers] = React.useState(0);
  const [totalPartners, setTotalPartners] = React.useState(0);
  const [activePartners, setActivePartners] = React.useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Get stats
  useEffect(() => {
    let fetchData = async () => {
      const admin = new AdminService();
      admin.getStats().then(data => {
        if(data?.result?.stats){
          var stats = data.result.stats;
          setTotalUsers(stats.totalUsers);
          setActiveUsers(stats.activeUsers);
          setTotalPartners(stats.totalPartners);
          setActivePartners(stats.activePartners);
        }
      });
    }
    fetchData();
  }, []);

  const listItem = [
    "User A",
    "User B",
    "User C"
  ];

  function MyVerticallyCenteredModal(props) {

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Payment Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Centered Modal</h4>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
              consectetur ac, vestibulum at eros.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const handleModal = async (p) => {
    await setModalShow(true);
    await setHistory(p)
  }

  return (
    <>
      <div className="row">
        
        <StatCard title="Total Partners" count={totalPartners} />
        <StatCard title="Active Partners" count={activePartners} />
        <StatCard title="Total Members" count={totalUsers} />
        <StatCard title="Active Members" count={activeUsers} />
        
        <div className="col-xl-12">
          <div className="card recent-tran">
            <div className="card-header border-0 p-3">
              <h4 className="text-black fs-20 mb-0">
                Recent Transactions
              </h4>
            </div>
            <div className="card-body p-0 ">
              <Table responsive hover size="sm">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>User Name</th>
                    <th>Type</th>
                    <th>Amount($)</th>
                  </tr>
                </thead>
                <tbody>
                  {payment.slice(0, 8).map((p) => {
                    return (
                      <Fragment key={p._id}>
                        <tr onClick={() => handleModal(p)} className="transaction">
                          <td>{p._id.slice(0, 6)}</td>
                          <td>{p.user_id ? p.user_id.name : "Demo"}</td>
                          <td>{p.payment_status}</td>
                          <td>{p.amount}</td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  payment={payment}
                />
              </Table>
            </div>
          </div>

        </div>


        {/* <div className="col-xl-6">
          <div className="card recent-chat">
            <div className="card-header border-0 p-3">
              <h4 className="text-black fs-20 mb-0">
                Recent Chats
              </h4>
            </div>
            <div className="card-body p-3">
              <Media>
                <img
                  width={40}
                  height={40}
                  className="mr-2"
                  src={profile}
                  alt="user"
                />
                <Media.Body>
                  <h5>Skrillex</h5>
                  <p>Hi ! How is the membership going ? <small className="float-right">2hrs ago</small></p>
                </Media.Body>
              </Media>
            </div>
          </div>
        </div> */}

        <div className="col-xl-12">
          <div className="card failed-tran">
            <div className="card-header border-0 p-3">
              <h4 className="text-black fs-20 mb-0">
                Failed Transactions
              </h4>
            </div>
            <div className="card-body p-0">
              <Table responsive hover borderless size="sm">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Attempted</th>
                    <th>Admin Club Name</th>
                    <th>User Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Contact</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ display: "none" }}>
                    <td>1.</td>
                    <td>Membership</td>
                    <td>Dubstep</td>
                    <td>Mark</td>
                    <td>Membership</td>
                    <td>$ 105.50</td>
                    <td>9699214575</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card" style={{ display: "none" }} >
            <Tab.Container defaultActiveKey="monthly">
              <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                <div className="mr-auto pr-3">
                  <h4 className="text-black fs-20">Recent Transcations</h4>
                  <p className="fs-13 mb-0 text-black">
                    Here is the list of last 10 transcations
                  </p>
                </div>
                {/* <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                  <Nav as="ul" className="nav nav-tabs" role="tablist">
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="monthly"
                        role="tab"
                      >
                        Monthly
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="weekly"
                        role="tab"
                      >
                        Weekly
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="today"
                        role="tab"
                      >
                        Today
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div> */}
              </div>
              <div className="card-body">
                <Card.Body>
                  <div className="basic-list-group">
                    <ListGroup as="ul">
                      {listItem.map((list, i) => (
                        <Fragment key={i}>
                          <ListGroup.Item as="li">{list}</ListGroup.Item>
                        </Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </Card.Body>
                {/* <Tab.Content className="tab-content">
                  <Tab.Pane
                    eventKey="monthly"
                    className="tab-pane fade"
                    id="Monthly"
                  >
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <RadialBarChart series={85} />
                      </div>
                      <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                        <h3 className="fs-28 text-black font-w600">
                          $456,005.56
                        </h3>
                        <span className="mb-3 d-block">from $500,000.00</span>
                        <p className="fs-14">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <Link
                          to="post-details"
                          className="btn btn-primary light btn-rounded"
                        >
                          More Details
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            25
                          </h3>
                          <span className="fs-18">On Delivery</span>
                        </div>
                      </div>
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            60
                          </h3>
                          <span className="fs-18">Delivered</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">7</h3>
                          <span className="fs-18">Canceled</span>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="weekly"
                    className="tab-pane fade"
                    id="Weekly"
                  >
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <RadialBarChart series={40} />
                      </div>
                      <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                        <h3 className="fs-28 text-black font-w600">
                          $150,002.00
                        </h3>
                        <span className="mb-3 d-block">from $400,000.00</span>
                        <p className="fs-14">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <Link
                          to="post-details"
                          className="btn btn-primary light btn-rounded"
                        >
                          More Details
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            30
                          </h3>
                          <span className="fs-18">On Delivery</span>
                        </div>
                      </div>
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            55
                          </h3>
                          <span className="fs-18">Delivered</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">9</h3>
                          <span className="fs-18">Canceled</span>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="today"
                    className="tab-pane fade"
                    id="Today"
                  >
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <RadialBarChart series={55} />
                      </div>
                      <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                        <h3 className="fs-28 text-black font-w600">
                          $856,005.56
                        </h3>
                        <span className="mb-3 d-block">from $800,000.00</span>
                        <p className="fs-14">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <Link
                          to="post-details"
                          className="btn btn-primary light btn-rounded"
                        >
                          More Details
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            45
                          </h3>
                          <span className="fs-18">On Delivery</span>
                        </div>
                      </div>
                      <div className="col-sm-4 mb-md-0 mb-3">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">
                            90
                          </h3>
                          <span className="fs-18">Delivered</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="p-3 border rounded">
                          <h3 className="fs-32 text-black font-w600 mb-1">3</h3>
                          <span className="fs-18">Canceled</span>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content> */}
              </div>
            </Tab.Container>
          </div>
        </div>
        <div className="col-xl-6" style={{ display: "none" }}>
          <div className="card">
            <div className="card-header d-sm-flex d-block pb-0 border-0">
              <div className="mr-auto pr-3">
                <h4 className="text-black fs-20">Revenue</h4>
                <p className="fs-13 mb-0 text-black">
                  Here is the recent revenue chart
                </p>
              </div>
              <Dropdown className="dropdown mt-sm-0 mt-3">
                <Dropdown.Toggle
                  type="button"
                  className="btn btn-primary light btn-rounded dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Monthly
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item className="dropdown-item" to="/">
                    Link 1
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item" to="/">
                    Link 2
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item" to="/">
                    Link 3
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="card-body" id="user-activity">
              <Tab.Container defaultActiveKey="all-food">
                <div className="d-flex flex-wrap mb-4">
                  <div className="mr-auto mb-2 pr-3 d-flex align-items-center">
                    <svg
                      width={25}
                      height={26}
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="3.54545"
                        height={26}
                        rx="1.77273"
                        fill="#EA7A9A"
                      />
                      <rect
                        x="7.09088"
                        y="7.09082"
                        width="3.54545"
                        height="18.9091"
                        rx="1.77273"
                        fill="#EA7A9A"
                      />
                      <rect
                        x="14.1819"
                        y="17.7271"
                        width="3.54545"
                        height="8.27273"
                        rx="1.77273"
                        fill="#EA7A9A"
                      />
                      <rect
                        x="21.2727"
                        y="3.54541"
                        width="3.54545"
                        height="22.4545"
                        rx="1.77273"
                        fill="#EA7A9A"
                      />
                    </svg>
                    <div className="ml-3">
                      <p className="fs-12 mb-1">Income</p>
                      <span className="fs-22 text-black font-w600">
                        $126,000
                      </span>
                    </div>
                  </div>
                  {/* <div className="card-action revenue-tabs">
                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="all-food"
                          role="tab"
                        >
                          All Food
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="food"
                          role="tab"
                        >
                          Food
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="beverages"
                          role="tab"
                        >
                          Beverages
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div> */}
                </div>
                <Tab.Content className="tab-content" id="myTabContent">
                  <Tab.Pane
                    className="tab-pane fade"
                    eventKey="all-food"
                    id="user"
                    role="tabpanel"
                  >
                    <ActivityLineChart />
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    eventKey="food"
                    id="user"
                    role="tabpanel"
                  >
                    <ActivityLineChart2 />
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    eventKey="beverages"
                    id="user"
                    role="tabpanel"
                  >
                    <ActivityLineChart3 />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8" style={{ display: "none" }}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <Tab.Container defaultActiveKey="monthly">
                  <div className="card-header d-sm-flex d-block pb-0 border-0">
                    <div className="mr-auto pr-3">
                      <h4 className="text-black fs-20">Admin Map</h4>
                      <p className="fs-13 mb-0 text-black">
                        Trend of Members joining
                      </p>
                    </div>
                    <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                      <Nav as="ul" className="nav nav-tabs" role="tablist">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="monthly"
                            role="tab"
                          >
                            Monthly
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="weekly"
                            role="tab"
                          >
                            Weekly
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="today"
                            role="tab"
                          >
                            Today
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <Tab.Content className="tab-content" id="myTabContent">
                      <Tab.Pane
                        className="tab-pane fade"
                        eventKey="monthly"
                        id="tab1"
                      >
                        <TimeLineChart />
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade"
                        eventKey="weekly"
                        id="tab2"
                      >
                        <TimeLineChart2 />
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade"
                        eventKey="today"
                        id="tab3"
                      >
                        <TimeLineChart3 />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>
            <div className="col-xl-8 col-xxl-12 col-lg-8">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="text-black fs-20 mb-0">
                    Active Subscription Summary
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 mb-sm-0 mb-3">
                      <div className="media align-items-center">
                        <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                          <ChartDonught2
                            backgroundColor="#53CB50"
                            backgroundColor2="#FAFAFA"
                            height="100"
                            width="100"
                            value="75"
                          />
                          <small className="text-black">86%</small>
                        </div>
                        <div>
                          <h4 className="fs-28 font-w600 text-black mb-0">
                            585
                          </h4>
                          <span>Subscribed</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="media align-items-center">
                        <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                          <ChartDonught2
                            backgroundColor="#FD376F"
                            backgroundColor2="#FAFAFA"
                            height="100"
                            width="100"
                            value="40"
                          />
                          <small className="text-black">14%</small>
                        </div>
                        <div>
                          <h4 className="fs-28 font-w600 text-black mb-0">
                            165
                          </h4>
                          <span>Unsubscribed</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-12 col-lg-4">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="text-black fs-20 mb-0">Average</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-end" id="BarChart">
                    <div>
                      <h4 className="fs-28 font-w600 text-black mb-0">
                        87,456
                      </h4>
                      <span>Subscription</span>
                    </div>
                    <BarChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12">
              <div className="card trending-menus">
                <div className="card-header d-sm-flex d-block pb-0 border-0">
                  <div>
                    <h4 className="text-black fs-20">Daily Trending Menus</h4>
                    <p className="fs-13 mb-0 text-black">Lorem ipsum dolor</p>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center">
                    <span className="num">#1</span>
                    <div className="mr-auto pr-3">
                      <Link to="post-details">
                        <h2 className="text-black fs-14">
                          Medium Spicy Spagethi Italiano
                        </h2>
                      </Link>
                      <span className="text-black font-w600 d-inline-block mr-3">
                        $5.6{" "}
                      </span>{" "}
                      <span className="fs-14">Order 89x</span>
                    </div>
                    <img
                      src={menu9}
                      alt="menu9"
                      width={60}
                      className="rounded"
                    />
                  </div>
                  <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center">
                    <span className="num">#2</span>
                    <div className="mr-auto pr-3">
                      <Link to="post-details">
                        <h2 className="text-black fs-14">
                          Watermelon juice with ice
                        </h2>
                      </Link>
                      <span className="text-black font-w600 d-inline-block mr-3">
                        $5.6{" "}
                      </span>{" "}
                      <span className="fs-14">Order 89x</span>
                    </div>
                    <img
                      src={menu10}
                      alt="menu10"
                      width={60}
                      className="rounded"
                    />
                  </div>
                  <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center">
                    <span className="num">#3</span>
                    <div className="mr-auto pr-3">
                      <Link to="post-details">
                        <h2 className="text-black fs-14">
                          Chicken curry special with cucumber
                        </h2>
                      </Link>
                      <span className="text-black font-w600 d-inline-block mr-3">
                        $5.6{" "}
                      </span>{" "}
                      <span className="fs-14">Order 89x</span>
                    </div>
                    <img
                      src={menu11}
                      alt="menu11"
                      width={60}
                      className="rounded"
                    />
                  </div>
                  <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center">
                    <span className="num">#4</span>
                    <div className="mr-auto pr-3">
                      <Link to="post-details">
                        <h2 className="text-black fs-14">
                          Italiano Pizza With Garlic
                        </h2>
                      </Link>
                      <span className="text-black font-w600 d-inline-block mr-3">
                        $5.6{" "}
                      </span>{" "}
                      <span className="fs-14">Order 89x</span>
                    </div>
                    <img
                      src={menu12}
                      alt="menu12"
                      width={60}
                      className="rounded"
                    />
                  </div>
                  <div className="d-flex tr-row align-items-center">
                    <span className="num">#5</span>
                    <div className="mr-auto pr-3">
                      <Link to="post-details">
                        <h2 className="text-black fs-14">
                          Tuna Soup spinach with himalaya salt
                        </h2>
                      </Link>
                      <span className="text-black font-w600 d-inline-block mr-3">
                        $5.6{" "}
                      </span>{" "}
                      <span className="fs-14">Order 89x</span>
                    </div>
                    <img
                      src={menu9}
                      alt="menu9"
                      width={60}
                      className="rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Home;
