import axios from "axios";

class BaseService {
    accessToken;

    constructor() {
        this.accessToken = localStorage.getItem("token")
        if (this.accessToken) {
            axios.defaults.headers.common['Authorization'] = this.accessToken;
        }
        axios.defaults.headers.common['Content-Type'] = 'application/json';
    }

    getBaseUrl(): string {
        return import.meta.env.VITE_BACKEND_API_URL;
    }
}

export{BaseService}