 const api_global_url = import.meta.env.VITE_BACKEND_API_URL;
const dev = {
    GET_ALL_SUBSCRIPTIONS: api_global_url + '/sasubscription',
    GET_ALL_USERS: api_global_url + '/user/getalluser',
    GET_ALL_SUB_ADMIN: api_global_url + '/subadmin',
    GET_ALL_PAYMENT: api_global_url + '/payment/getAllPayment',
    SUBADMIN_REGISTER_API_URL: api_global_url + '/subadmin/register',
    SUBSCRIPTION_REGISTER_API_URL: api_global_url + '/sasubscription/register',
    SEND_MAIN_API_URL: api_global_url + '/subadmin/sendmail',
    GET_SUBADMIN_API_URL: api_global_url + '/subadmin',
    PAYMENT_API_URL: api_global_url + '/subadmin/createPaypalTransaction'
}
const prod = {
    GET_ALL_SUBSCRIPTIONS: api_global_url + '/sasubscription',
    GET_ALL_USERS: api_global_url + '/user/getalluser',
    GET_ALL_SUB_ADMIN: api_global_url + '/subadmin',
    GET_ALL_PAYMENT: api_global_url + '/payment/getAllPayment',
    SUBADMIN_REGISTER_API_URL: api_global_url + '/subadmin/register',
    SUBSCRIPTION_REGISTER_API_URL: api_global_url + '/sasubscription/register',
    SEND_MAIN_API_URL: api_global_url + '/subadmin/sendmail',
    GET_SUBADMIN_API_URL: api_global_url + '/subadmin',
    PAYMENT_API_URL: api_global_url + '/subadmin/createPaypalTransaction'
}
const APIConfig = import.meta.env.NODE_ENV == 'development' ? dev : prod
export default APIConfig
