import React, { useEffect, useState } from "react";  // Removed useRef, unnecessary import
import { Button, Modal } from 'react-bootstrap';
import { sasubscription,updatePartnerSubsPlan } from '../../../helper/service';
import axios from 'axios';
import { setValue } from "@syncfusion/ej2-base";

const PartnerUpdateSubscription = (props) => {
   const [showAllSubscriptions, setshowAllSubscriptions] = useState([]);
   const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();
   const [selectedSubscriptionType, setSelectedSubscriptionType] = useState("");
   
   const [selectPackage, setSelectPackage] = useState('');

   useEffect(() => {
      getSubscriptionList();
   }, []);

   const sendPartnerDetails = async () => {
const updatePartnerSubs={
   subsId:selectedSubscriptionId,
   subsSelectedPackage:selectPackage?selectPackage:" ",
   partnerFirstName:props.partnerData.firstName,
   partnerLastName:props.partnerData.lastName,
   partnerEmail:props.partnerData.email
}
console.log(updatePartnerSubs,"updatePartnerSubs")
      
let res=await updatePartnerSubsPlan(updatePartnerSubs)
console.log(res,"ttttttttttt")

   }
   const selectedSubs = (event) => {
      let subsData=event.target.value.split(',');
      setSelectedSubscriptionId(subsData[0])
      setSelectedSubscriptionType(subsData[1])
   }
   const selectedPackage = (event) => {
      setSelectPackage(event.target.value);
   }

   const getSubscriptionList = async () => {
      try {
         let listSub = await sasubscription();
         setshowAllSubscriptions(listSub.result.subscriptions);
         console.log(listSub.result.subscriptions, "Available subscriptions here");
      } catch (error) {
         console.error(error);
      }
   }


   const closeModal = () => {
      props.setshowSubscriptionList(false);
   }

   const handleFormSubmit = (e) => {
      e.preventDefault();
   }
   return (
      <div className="bootstrap-modal">
         <Modal
            className="fade bd-example-modal-lg"
            show={props.showSubscriptionList}
            size="lg"
            backdrop='static'
         >
            <Modal.Header>
               <Modal.Title>Change Partner Plan</Modal.Title>
               <Button
                  variant=""
                  className="close"
                  onClick={closeModal}
               >
                  <span>&times;</span>
               </Button>
            </Modal.Header>
            <Modal.Body>
               <div className="modal-body">
                  <form name="form" onSubmit={handleFormSubmit}>
                     <div className='row'>
                        <div className="form-group col-md-12">
                           <label>Available Subscriptions</label>
                           <div className="topic">
                              <select name="subsType" className="form-control" onChange={selectedSubs}>
                                 <option>Subscriptions</option>
                                 {showAllSubscriptions.map((option, index) => (
                                    <option key={index} value={`${option._id},${option.substype}`}>{option.planName}</option>
                                 ))}
                              </select>
                           </div>
                        </div>
                        {(selectedSubscriptionType == 'Free' || selectedSubscriptionType=="")? <></> : (
                           <div className="form-group col-md-12">
                              <label>Package</label>
                              <div className="topic">
                                 <select name="memberPackage" className="form-control" onChange={selectedPackage}>
                                    <option value="">Please Select</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                 </select>
                              </div>
                           </div>
                        )}

                     </div>
                     <div className="d-flex justify-content-center">
                        <button
                           type="button"
                           className="btn btn-custom"
                           onClick={() => { sendPartnerDetails() }}
                        >
                           Update Plan
                        </button>
                     </div>
                  </form>
               </div>
            </Modal.Body>
         </Modal>
      </div>
   );
}

export default PartnerUpdateSubscription;
