import axios from "axios";
import { BaseService } from './baseService';

class EmailTemplatesService extends BaseService {

    getTemplates = async () => {
        let url = this.getBaseUrl();
        return axios.get(`${url}/emailtemplate`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => console.log(error.message))
    }

    deleteTemplate = async (name: string) => {
        let url = this.getBaseUrl();
        return axios.delete(`${url}/emailtemplate/${name}`)
            .then((res) => {
                return res;
            })
            .catch((error) => console.log(error.message))
    }
}

export { EmailTemplatesService };