import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Drp from "react-dropzone-uploader";
const Drop = Drp?.default ? Drp.default : Drp; // https://github.com/vitejs/vite/issues/2139#issuecomment-802981228
import axios from 'axios';
import swal from "sweetalert";
import APIConfig from "../../../config";

const DropFile = (props) => {
   // specify upload params and url for your files
   // const getUploadParams = ({ meta }) => {
   //    return { url: "https://httpbin.org/post" };
   // };
   const [file,setFiles] = React.useState({});
   // called every time a file's `status` changes
   const handleChangeStatus = ({ meta, file }, status) => {
      console.log(file);
      setFiles(file)
   };

   React.useEffect(() => {
      handleSubmit(file)
   },[props.send])

   // receives array of files that are done uploading when submit button is clicked
   const handleSubmit = async (file) => {
      if(Object.keys(file).length !== 0 || Object.keys(props.email).length !== 0){

         console.log(file)
         let formData = new FormData();
        formData.append("image",file)
        formData.append("email",props.email.email)
        formData.append("subject",props.email.subject)
        formData.append("body",props.email.body)
      //   for (let file of files){
      //       formData.append("files",file.meta)
      //       console.log(file.meta)
      //   }
      var accessToken =  localStorage.getItem("token")
      let config = {
      
      headers: {
      'Content-Type': 'multipart/form-data', 
      "Authorization" : accessToken
     }
    
    }
      await axios.post(`${APIConfig.SEND_MAIN_API_URL}`,formData,config).then((res) =>{
     console.log(res)
     if(res.data.status === 200){
       swal(
         "Good job!",
         "Email sent succefully",
         "success"
      )
      window.location.reload();
      // document.getElementById("email2").value = ""
      // document.getElementById("body2").value = ""
      // document.getElementById("subject2").value = ""
      
     }
   }).catch((error) => console.log(error))
      }
      
      
   };
   return (
      <Drop
         // getUploadParams={getUploadParams}
         onChangeStatus={handleChangeStatus}
         onSubmit={handleSubmit}
         inputContent="Drop files here to upload"
         accept="image/*,audio/*,video/*"
         styles={{
            dropzone: {
               minHeight: 200,
               maxHeight: 250,
               width: "100%",
               backgroundColor: "#f2f4fa",
               border: "1px dashed #DDDFE1",
               overflow: "hidden",
            },
            inputLabel: {
               color: "#7e7e7e",
               fontSize: "18px",
               fontWeight: "normal",
               backgroundColor: "#f2f4fa",
            },
         }}
      />
   );
};

export default DropFile;