import React from "react";

/// React router dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Subscriptions from "./components/Dashboard/Subscriptions";
import Email from "./components/Dashboard/Email";
import GeneralCustomers from "./components/Dashboard/GeneralCustomers";
import CreateSubscription from "./components/Dashboard/createSubscription";
import Users from "./components/Dashboard/Users";
import CreateOrganizer from "./components/Dashboard/createOrganizer";
import Login from "./pages/Login";
import AppProfile from './components/AppsMenu/AppProfile/AppProfile';
import AdminProfile from "./components/AdminProfile/AdminProfile";
import UserProfile from "./components/UserProfile/UserProfile";
import EmailTemplates from "./pages/EmailTemplates"

const Markup = () => {

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("")

  return (
    <Router >
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${!path ? "right-profile" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Routes>
              <Route exact path="/home" element={<Home/>} />
              <Route exact path="/" element={<Login/>} />
              <Route exact path="/subscriptions" element={<Subscriptions/>} />
              <Route exact path="/partners" element={<GeneralCustomers/>} />
              <Route exact path="/users" element={<Users/>} />
              <Route exact path="/create-subscription" element={<CreateSubscription/>} />
              <Route exact path="/create-organizer" element={<CreateOrganizer/>} />
              <Route exact path="/email" element={<Email/>} />
              <Route exact path="/app-profile" element={<AppProfile/>} />
              <Route exact path="/admin-profile" element={<AdminProfile/>} />
              <Route exact path="/user-profile" element={<UserProfile/>} />
              <Route exact path="/email-templates" element={<EmailTemplates/>} />
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </Router>
  );
};

export default Markup;