import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import DeleteConfirm from "../../modals/deleteConfirmation";
import { Button } from 'react-bootstrap'
import BSB from 'bootstrap-switch-button-react'
const BootstrapSwitchButton = BSB?.default ? BSB.default : BSB; // https://github.com/vitejs/vite/issues/2139#issuecomment-802981228
import ViewUser from "../../modals/viewUser";
import EditSubscription from "../../modals/editSubscription";
import APIConfig from "../../../config";


function Users() {
  //Time Picker
  // const [selectedDate, handleDateChange] = useState(new Date());
  const [show, setShow] = useState(false);
  const [users, viewSubs] = useState({});
  const [modal, viewModal] = useState(false)
  const [edit, editModal] = useState({});
  const [viewEdit, viewEditModal] = useState(false);

  const [data, setData] = useState(
    document.querySelectorAll("#orders tbody tr")
  );

  const [subscription, setUser] = useState([]);
  const sort = 7;
  const activePag = useRef(0);
  const [id, setId] = useState(null);
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const updateSubs = (row) => {
    viewSubs(row)
    viewModal(true)
  }

  // const editSub = (row) => {
  //   editModal(row)
  //   viewEditModal(true)
  // }

  const closeEdit = () => {
    viewEditModal(false)
  }

  const deleteSubs = (e, id) => {
    e.preventDefault();
    setShow(true)
    setId(id)
  }

  const showModal = () => {
    setShow(false)
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#orders tbody tr"));
    let fetchData = async () => {
      var accessToken = localStorage.getItem("token")
      let config = {
        headers: {
          "Authorization": accessToken
        }

      }
      await axios.get(`${APIConfig.GET_ALL_USERS}`, config).then((res) => {
        if (res.data.result.validUser.length) {
          setUser(res.data.result.validUser);
        }
      })
    }
    fetchData();
  }, [subscription]);

  const closeUser = () => {
    viewModal(false)
  }

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };

  return (
    <>
      {show ? <DeleteConfirm modal={showModal} show={show} id={id} method={"Subscription"} /> : ""}
      {modal ? <ViewUser modal={closeUser} show={viewModal} users={users} /> : ""}
      {/* {viewEdit ? <EditSubscription modal={closeEdit} show={viewEditModal} subscription={edit}/>: ""} */}
      <div className="d-sm-flex mb-lg-4 mb-2">
        <Dropdown className="dropdown mb-2 ml-auto mr-3">
          <Dropdown.Toggle
            to="#"
            className="i-false btn btn-primary btn-rounded light"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="las la-bolt scale5 mr-3" />
            All Status
            <i className="las la-angle-down ml-3" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
            <Dropdown.Item className="dropdown-item" to="#;">
              <span className="text-primary">Active</span>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" to="#;">
              <span className="text-primary">Not-Active</span>
            </Dropdown.Item>
            {/* <Dropdown.Item className="dropdown-item" to="#;">
              <span className="text-success">Delivery</span>
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
        {/* <input
          className="d-inline-block form-control date-button btn btn-primary light btn-rounded"
          id="timepicker"
          placeholder="Today"
        /> */}
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            className="d-inline-block form-control date-button btn btn-primary light btn-rounded"
            autoOk
            label=""
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider> */}
        {/* <div className="d-sm-flex mb-lg-4 mb-2" style={{float:"right"}}>
       <Link to="/create-subscription"> <Button>New Subscription</Button> </Link>
        </div> */}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive rounded card-table">
            <div id="orders" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: 74 }}
                    >
                      ID
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: 174 }}
                    >
                      First Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: 133 }}
                    >
                      Last Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Location: activate to sort column ascending"
                      style={{ width: 193 }}
                    >
                      Email
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Amount: activate to sort column ascending"
                      style={{ width: 67 }}
                    >
                      Phone
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status Order: activate to sort column ascending"
                      style={{ width: 108 }}
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label=": activate to sort column ascending"
                      style={{ width: 28 }}
                    />
                  </tr>
                </thead>
                {subscription.length === 0 ? <tbody><tr>
                  <td colspan="7"><div class="text-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div></td></tr></tbody> : <tbody>
                  {subscription.map((row) => {
                    return <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td className="sorting_1">{row._id.toString(36).slice(20)}</td>
                      <td>{row.firstName}</td>
                      <td>{row.lastName ? row.lastName : "-"}</td>
                      <td>{row.email ? row.email : "-"}</td>
                      <td>{row.mobile ? row.mobile : "-"}</td>
                      <td><BootstrapSwitchButton onlabel="." offlabel="." checked={row.status === "Active" ? true : false} /></td>
                      <td>
                        <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            variant=""
                            className="i-false"
                            to="#"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                            <Dropdown.Item className="dropdown-item" to="#" >
                              <Link
                                key={row._id}
                                onClick={() => {
                                  updateSubs(row)
                                }
                                }
                              >
                                <i className="las la-check-circle text-success mr-3 scale5" />
                                View
                              </Link>
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                            to="#"
                            data-dismiss="alert"
                            aria-label="Close"
                            className="dropdown-item"
                          >
                            <Link
                             key={row._id}
                             onClick={() => {
                              editSub(row)}
                             }
                            >
                           <i className="las la-check-circle text-success mr-3 scale5" />
                            Update
                            </Link>
                            
                          </Dropdown.Item> */}
                            <Dropdown.Item
                              to="#"
                              data-dismiss="alert"
                              aria-label="Close"
                              className="dropdown-item"
                            >
                              <Link
                                key={row._id}
                                onClick={(e) => {
                                  deleteSubs(e, row._id)
                                }
                                }
                              >
                                <i className="las la-times-circle text-danger mr-3 scale5" />
                                Delete
                              </Link>

                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  })}
                </tbody>}

              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div
                  className="dataTables_info"
                  id="example5_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    to="/users"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                    className="paginate_button previous disabled"
                  >
                    Previous
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/users"
                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                          } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/users"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
