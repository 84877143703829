/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Fragment } from 'react';
import { Button ,Modal } from 'react-bootstrap';
import {  Card, ListGroup } from "react-bootstrap";
import { Container, Row, Col  } from 'react-bootstrap';

const ViewUser = (props) => {

  const handleClose = () => { 
    props.modal();
    console.log(props.users)
  }
  return ( 
    <Modal
    className="fade bd-example-modal-lg"
    show={props.show}
    size="lg"
 >
    <Modal.Header>
       <Modal.Title>User Details</Modal.Title>
       <Button
          variant=""
          className="close"
          onClick={handleClose}
       >
          <span>&times;</span>
       </Button>
    </Modal.Header>
    <Modal.Body>   
         <Row>
            <Col lg={12} md={12} className="mb-3 text-center">
               <label>Logo:</label>
               <img src="{props.users.logo ? props.users.logo: 'sample.jpg'}"></img>
    
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>ID:</label>
              <h6>{props.users._id}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Name</label>
              <h6>{props.users.firstName} {props.users.lastName}</h6>
            </Col>
           
            <Col md={12} lg={6} className="mb-3">
              <label>Email:</label>
              <h6>{props.users.email}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Phone Number</label>
              <h6>{props.users.mobile}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Status:</label>
              <h6>{props.users.status}</h6>
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <label>Logo:</label>
              <h6>{props.users.image ? props.users.image: "sample.jpg"}</h6>
            </Col>
         </Row>
      {/* <Card style={{overflow:"hidden"}}>
         <Card.Header>
            <Card.Title>{props.businessName}</Card.Title>
         </Card.Header>
         <Card.Body>
            <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                 <tbody style={{display:"table-caption"}}>
                  <tr>ID: {props.organizer._id}</tr>
                    <tr>First Name: {props.organizer.firstName}</tr>
                    <tr>Last Name: {props.organizer.lastName}</tr>
                    <tr>Email: {props.organizer.email}</tr>
                    <tr>Phone Number: {props.organizer.phoneNumber}</tr>
                    <tr>Business Name: {props.organizer.businessName}</tr>
                    <tr>Membership Name: {props.organizer.memberShipName ? props.organizer.memberShipName: "MemberApp"}</tr>
                    <tr>Logo: {props.organizer.logo ? props.organizer.logo: "sample.jpg"}</tr>
                    <tr>Brand Color: {props.organizer.brandColor ? props.organizer.brandColor: "#FFF090"}</tr>
                    <tr>Status: {props.organizer.status}</tr> 
                 </tbody>
                 </table>
            </Card.Body>
          </Card> */}
          </Modal.Body>
    <Modal.Footer>
       <Button
          variant="danger light"
          onClick={handleClose}
       >
          Close
       </Button>
       {/* <Button
          variant=""
          type="button"
          className="btn btn-primary"
          onClick={handleClose}
       >
          Save changes
       </Button> */}
    </Modal.Footer>
 </Modal>
   );
}
 
export default ViewUser;