import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/App-icon-circle.png";
import logoText from "../../../images/logo-text.png";
import logo2 from "../../../images/Mask-Group-422.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         <Link to="/home" className="brand-logo">
            <img className="logo-abbr" src={logo2} alt="" style={{maxWidth:"50%",position:"absolute"}}/>
            {/* <img className="logo-compact" src={logoText} alt="" /> */}
            {/* <img className="brand-title" src={logoText} alt="" /> */}
            {/* <h2 className="brand-title">MemberApp</h2> */}
         </Link>
         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
