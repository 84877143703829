import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import { Button, Row, Col, Form } from "react-bootstrap";
//** Import Image */
// import profile01 from "../../../../images/profile/1.jpg";
// import profile02 from "../../../../images/profile/2.jpg";
// import profile03 from "../../../../images/profile/3.jpg";
// import profile04 from "../../../../images/profile/4.jpg";
// import profile05 from "../../../../images/profile/5.jpg";
// import profile06 from "../../../../images/profile/6.jpg";
// import profile07 from "../../../../images/profile/7.jpg";
// import profile08 from "../../../../images/profile/8.jpg";
// import profile09 from "../../../../images/profile/9.jpg";
// import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
import "./styles.css";
import profileAvatar from "../../../../images/proavatar.png";
import showPasswordIcon from "../../../../images/visibility.svg";
import useUserInfo from "../../../../hooks/useUserInfo";
import { updateAdmin } from "../../../../helper/service";

// TODO: updating profile image is not working

const AppProfile = (props) => {
  const { userInfo } = useUserInfo();
  const [image, setImage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: userInfo.firstName,
      phoneNumber: userInfo.phoneNumber,
      email: userInfo.email,
      password: userInfo.password,
      abn: userInfo.aBN,
      website: userInfo.website,
      location: userInfo.location,
      termsAndcondition: userInfo.termsAndcondition,
    },
    onSubmit: async (values) => {
      console.log("values", values);
      const res = await updateAdmin(userInfo._id, values);
      console.log("res", res);
      if (res && res?.data) {
        localStorage.setItem("userInfo", JSON.stringify({ result: res.data?.result?.updateadmin }));
        alert("Profile updated successfully");
      }
    },
    enableReinitialize: true,
  });

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    //console.log("fileupload",fileUploaded)
    setImage(fileUploaded);
  };

  //console.log("image", image)

  return (
    <Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg={12}>
            <PageTitle activeMenu="Profile" motherMenu="App" />
            <div className="profileHeader">
              <h5 className="profileHeaderText">Account Details - Personal Profile</h5>
            </div>

            <div className="editIconContainer">
              <>
                <img src={image ? image : profileAvatar} alt="" className="avatarImage" />
                <div className="iconContainer">
                  <i
                    className="fa fa-pencil editIcon icons"
                    aria-hidden="true"
                    onClick={handleClick}
                  ></i>
                </div>
              </>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                className="imagePicker"
              />
            </div>
          </Col>
          <Col lg={6}>
            <Form.Group as={Row} className="mb-3 mt-35" controlId="appProfileUsername">
              <Form.Label column sm={3} className="textStyle">
                Username
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  name="firstName"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.firstName}
                  className="textInput"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="appProfileContactNumber">
              <Form.Label column sm={3} className="textStyle">
                Contact no.
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Contact Number"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.phoneNumber}
                  className="textInput"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="appProfileEmail">
              <Form.Label column sm={3} className="textStyle">
                Email Address
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.email}
                  className="textInput"
                />
              </Col>
            </Form.Group>
            {userInfo?.password && (
              <Form.Group as={Row} className="mb-3" controlId="appProfilePassword">
                <Form.Label column sm={3} className="textStyle">
                  Password
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    onChange={formik.handleChange}
                    defaultValue={formik?.values?.password}
                    className="textInput position-relative pr-5"
                  />
                  <img
                    src={showPasswordIcon}
                    alt="show password"
                    onClick={() => setShowPassword(!showPassword)}
                    className="showPasswordIcon"
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} className="mb-3" controlId="appProfileABN">
              <Form.Label column sm={3} className="textStyle">
                ABN
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="ABN"
                  name="abn"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.abn}
                  className="textInput"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="appProfileWebsite">
              <Form.Label column sm={3} className="textStyle">
                Website
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Website"
                  name="website"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.website}
                  className="textInput"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="appProfileLocation">
              <Form.Label column sm={3} className="textStyle">
                Location
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Location"
                  name="location"
                  onChange={formik.handleChange}
                  defaultValue={formik?.values?.location}
                  className="textInput"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="textStyle">T&C’s / Privacy Policy</Form.Label>
              <Form.Control
                as="textarea"
                rows={16}
                className="textInput"
                name="termsAndcondition"
                onChange={formik.handleChange}
                defaultValue={formik?.values?.termsAndcondition}
              />
            </Form.Group>
          </Col>
          <Col lg={12} className="d-flex justify-content-center">
            <Button type="submit" variant="custom">
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AppProfile;
